import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ContactItem} from '../../models/contact-item';
import {AdministrationService} from '../../administration.service';
import {AuthService} from '../../../auth/auth.service';
import {ToolsService} from '../../../shared/services/tools.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastService} from 'ng-uikit-pro-standard';
import {User} from '../../../auth/models/user';

@Component({
  selector: 'app-contact-add',
  templateUrl: './contact-add.component.html'
})
export class ContactAddComponent implements OnInit {
  contactForm: UntypedFormGroup;
  contactItem: ContactItem;
  isDataLoaded = false;
  showChangePassword = false;
  @Input() refCustomerId = 0;
  @Output() returnClicked = new EventEmitter<any>();
  private wasCPAccess = false;
  user: User;

  constructor(private formBuilder: UntypedFormBuilder,
              private administrationService: AdministrationService,
              private authService: AuthService,
              private toolsService: ToolsService,
              private router: Router,
              private route: ActivatedRoute,
              private toastService: ToastService) {
  }

  ngOnInit(): void {
    this.user = this.authService.getCurrentUser();
    this.contactForm = this.buildContactForm();
    this.wasCPAccess = false;
    this.isDataLoaded = true;
  }

  buildContactForm() {
    return this.formBuilder.group({
      caseNotifications: this.formBuilder.group({
        added: [false],
        assigned: [false],
        closed: [false],
        closingProposal: [false],
        autoClose: [false],
        commentAdded: [false],
        ownAdded: [true],
        ownAssigned: [true],
        ownClosed: [true],
        ownClosingProposal: [true],
        ownAutoClose: [true],
        ownCommentAdded: [true],
      }),
      email: ['', [Validators.required, Validators.email]],
      email2: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phone: [''],
      phone2: [''],
      jobTitle: [''],
      forNotification: [false],
      salutation: [''],
      active: [true],
      verified: [true],
      cpAccess: [false],
      cpCaseAccess: [false],
      cpUpdateAccess: [false],
      cpAdmin: [false],
      cpReportAccess: [false],
      cpServiceAccess: [false],
      toNews: [false],
      toOffers: [false],
      toUpdates: [false],
      forMienie: [false],
      forMapa: [false],
      forOsrodek: [false],
    });
  }

  submit() {
    if (this.contactForm.valid) {
      this.contactItem = Object.assign({}, this.contactForm.value);
      this.contactItem.cusId = this.refCustomerId;
      this.administrationService.addContact(this.contactItem)
        .subscribe(
          data => {
            this.router.navigate(['/administracja/uzytkownicy/edycja/' + data]);
            this.toastService.info('Dane użytkownika zostały zapisane.', 'Potwierdzenie');
          },
          (ex) => {
            this.toastService.error(ex, 'Błąd');
          });
    } else {
      this.toolsService.validateAllFormFields(this.contactForm);
    }
  }

  return() {
    if (this.refCustomerId !== 0) {
      this.returnClicked.emit();
    } else {
      this.router.navigate(['administracja/uzytkownicy']);
    }
  }
}
