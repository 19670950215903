import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {DynamicTable} from '../shared/models/table';
import {TrainingsService} from './trainings.service';

@Injectable()
export class TrainingsResolve implements Resolve<DynamicTable> {
  constructor(private trainingsService: TrainingsService) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.trainingsService.getAvailableTrainings('');
  }
}

// @Injectable()
// // export class CaseByIdResolve implements Resolve<CaseItem> {
// //   constructor(private casesService: CasesService) { }
// //
// //   resolve(route: ActivatedRouteSnapshot) {
// //     return this.casesService.getCaseById(route.params['id']);
// //   }
// // }
