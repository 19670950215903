import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CaseDashboard, PersonFullData, TrainingDashboard} from './models/dashboard';

import {map} from 'rxjs/operators';

@Injectable()
export class DashboardService {

  private apiUrl = 'api/profile';
  constructor(private http: HttpClient) { }

  getProfile(): Observable<PersonFullData> {
    return this.http.get(this.apiUrl).pipe(
      map((res) => res as PersonFullData)
    );
  }

  getCases(): Observable<CaseDashboard> {
    return this.http.get('api/cp/cases/dashboard').pipe(
      map((res) => res as CaseDashboard)
    );
  }

  getTrainings(): Observable<TrainingDashboard> {
    return this.http.get('api/trainings/dashboard').pipe(
      map((res) => res as TrainingDashboard)
    );
  }
}
