import {Route, RouterModule} from '@angular/router';
import {AuthGuard} from '../auth/guard/auth.guard';
import {NgModule} from '@angular/core';
import {CaseByIdCommentsResolve, CaseByIdResolve, CasesResolve} from './cases-resolve.service';
import {CaseListComponent} from './components/case-list/case-list.component';
import {CaseAddComponent} from './components/case-add/case-add.component';
import {CaseEditComponent} from './components/case-edit/case-edit.component';

const CASES: Route[] = [
  {
    path: 'sprawy',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: <any>CaseListComponent,
        resolve: {
          cases: CasesResolve
        }
      },
      {
        path: 'dodaj',
        component: <any>CaseAddComponent
      },
      {
        path: ':id',
        component: <any>CaseEditComponent,
        resolve: {
          case: CaseByIdResolve,
          caseComments: CaseByIdCommentsResolve
        }
      }
    ]
  }
]

@NgModule({
  imports: [
    RouterModule.forChild(CASES)
  ],
  exports: [
    RouterModule
  ]
})

export class CasesRoutingModule {}
