import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseListComponent } from './components/case-list/case-list.component';
import {CaseByIdCommentsResolve, CaseByIdResolve, CasesResolve} from './cases-resolve.service';
import {SharedModule} from '../shared/shared.module';
import { CaseAddComponent } from './components/case-add/case-add.component';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {MDBBootstrapModulesPro} from 'ng-uikit-pro-standard';
import {RouterModule} from '@angular/router';
import {FilesModule} from '../files/files.module';
import { CaseEditComponent } from './components/case-edit/case-edit.component';
import { CaseCommentComponent } from './components/case-comment/case-comment.component';

@NgModule({
  declarations: [CaseListComponent, CaseAddComponent, CaseEditComponent, CaseCommentComponent],
  exports: [CaseListComponent],
  imports: [
    BrowserModule,
    CommonModule,
    FilesModule,
    FormsModule,
    HttpClientModule,
    MDBBootstrapModulesPro.forRoot(),
    ReactiveFormsModule,
    RouterModule,
    SharedModule
  ],
  providers: [CasesResolve, CaseByIdResolve, CaseByIdCommentsResolve]
})
export class CasesModule { }
