import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MDBBootstrapModulesPro} from 'ng-uikit-pro-standard';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import {AuthRoutingModule} from './auth-routing.module';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ResetPasswordAttemptComponent } from './components/reset-password-attempt/reset-password-attempt.component';

@NgModule({
  declarations: [LoginComponent, ChangePasswordComponent, ResetPasswordComponent, ResetPasswordAttemptComponent],
  imports: [
    AuthRoutingModule,
    CommonModule,
    FormsModule,
    MDBBootstrapModulesPro,
    ReactiveFormsModule
  ],
})
export class AuthModule { }
