<div class="row" *ngIf="documentationViewStatus===0 ; else documentationPreview">
  <div class="col-12">
    <app-table-main *ngIf="documentationTable"
                    (reload)="documentationReload()"
                    tableCaption="Dokumentacja"
                    [dynamicTable]="documentationTable"
                    [butAddVisible]="false"
                    (selectedIdChange)="goToDetails($event)">
    </app-table-main>
  </div>
</div>
<ng-template #documentationPreview>
  <div class="row">
    <div class="col-12">
      <!--<app-documentation-add-edit-->
        <!--[refDocumentationId]="documentationId"-->

        <!--(returnClicked)="documentationReturn($event)"-->
        <!--[isAdd]="documentationId === -1 ? true : false"-->
      <!--&gt;-->
      <!--</app-documentation-add-edit>-->
    </div>
  </div>
</ng-template>


<div mdbModal #filesModal="mdb-modal" class="modal fade right" id="fullHeightModalRight" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-full-height modal-right" role="document" style="width: 500px">
    <div class="modal-content">
      <div class="modal-header light-blue darken-3 white-text">
        <h5 class="modal-title w-100" id="myModalLabel">Wybierz pliki do pobrania</h5>
        <button type="button" class="close white-text" data-dismiss="modal" aria-label="Close" (click)="filesModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
<!--        <p>Zaznacz pliki i wcośnij przycisk pobierz.-->
<!--        </p>-->
        <div class="">
          <!--<div class="table-responsive" style="max-height: 300px; overflow-y: auto; overflow-x: hidden;">-->
          <table class="table table-sm">
            <thead>
            <tr class="rgba-stylish-strong white-text small">
<!--              <th style="width: 40px"></th>-->
              <th style="width: 360px">Nazwa</th>
              <th style="width: 100px">Wielkość</th>
              <!--<th>Do</th>-->
              <!--<th>Wolne miejsca</th>-->
            </tr>
            </thead>
            <tbody class="columnsList">



            <tr *ngFor="let file of documentationFiles; let i = index" (click)="downloadFile(file.name)" style="cursor: pointer">
              <!--<td><input class="form-check-input" mdbInputDirective type="checkbox" id="zzzx1"></td>-->
              <!--<td><input type="checkbox" id="zzzx1"></td>-->
              <!--<td style="width: 40px">-->
              <!--&lt;!&ndash;<div class="form-check">&ndash;&gt;-->

              <!--<input class="form-check-input" mdbInputDirective type="checkbox" id="cb{{i}}" (change)="updateCheckedOptions(file, $event)">-->
              <!--<label class="form-check-label" for="cb{{i}}"> </label>-->

              <!--&lt;!&ndash;</div>&ndash;&gt;-->
              <!--</td>-->
<!--              <td style="width: 40px"><span><i class="fa fa-download text-primary"></i></span></td>-->
              <td style="width: 360px; overflow: hidden; text-overflow: ellipsis">{{file.name}}</td>
              <td style="width: 100px; text-align: right">{{file.size | fileSize}}</td>
              <!--<td>{{training.to}}</td>-->
              <!--<td ><span class="badge green">{{training.available}}</span></td>-->
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--<div class="row">-->
      <!--<div class="col-12">-->
      <!--<div class="form-check">-->
      <!--<input class="form-check-input" mdbInputDirective type="checkbox" id="checkbox1">-->
      <!--<label class="form-check-label" for="checkbox1">Pobierz wszystkie jako zip.</label>-->
      <!--</div>-->
      <!--</div>-->
      <!--</div>-->
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-default ml-auto" (click)="download()" mdbWavesEffect>Pobierz wszystkie</button>
        <button type="button" class="btn btn-outline-default waves-light" data-dismiss="modal" (click)="filesModal.hide()" mdbWavesEffect>Zamknij</button>
      </div>
    </div>
  </div>
</div>








