<div class="modal-content">
  <div class="modal-header red-button darken-3 white-text">
    <h4 class="modal-title w-100" style="text-align: center" id="myModalLabel">{{header}}</h4>
  </div>
  <div class="modal-body">
    <mdb-progress-bar class="progress primary-color-dark" mode="indeterminate"></mdb-progress-bar>
    <div class="modal-title text-center mt-3">
      {{content}}
    </div>
  </div>
</div>

