<div *ngIf="isDataLoaded">
  <!--<div>-->
  <!--<div *ngIf="refCriticalityId===0; else caption"-->
  <!--class="view form-header p-2 mt-0 align-items-center">-->
  <!--<h4 class="h4-responsive">{{isAdd ? 'Dodawanie kontaktu' : 'Edycja kontaktu'}}</h4>-->
  <!--</div>-->

  <!--<ng-template #caption>-->
<!--  <div class="view form-header p-2 mt-0 mb-0 d-flex justify-content-between align-items-center">-->
<!--    <button (click)="return()" class="btn btn-default waves-light btn-sm px-3" mdbTooltip="Powrót" placement="right">-->
<!--      <i-->
<!--        class="fa fa-arrow-left"></i></button>-->
<!--    <h5 class="font-bold">{{isAdd ? 'Dodawanie kontaktu' : 'Edycja kontaktu - ' + contactItem.firstName  + ' ' + contactItem.lastName}}</h5>-->
<!--    <div></div>-->
<!--  </div>-->
  <!--</ng-template>-->

    <div class="row ">
      <div class="col-lg-4">
        <div class="card-body">
          <h5 class="mt-4 pb-2">Dane użytkownika</h5>
          <div class="row">
            <div class="col-12">
              <div class="md-form mb-2">
                <input class="form-control form-control-sm" id="fEmail" value="{{customerContactItem.email}}" mdbInput
                       type="text" disabled>
                <label class for="fEmail">Login/Email</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="md-form mb-2">
                <input class="form-control form-control-sm" id="fFirstname" value="{{customerContactItem.firstName}}" mdbInput
                       type="text" disabled>
                <label class for="fFirstname">Imię</label>
              </div>
            </div>
            <div class="col-6">
              <div class="md-form mb-2">
                <input class="form-control form-control-sm" id="fLastName" value="{{customerContactItem.lastName}}" mdbInput
                       type="text" disabled>
                <label class for="fLastName">Nazwisko</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="md-form">
                <input class="form-control form-control-sm" id="fPhone" value="{{customerContactItem.phone}}" mdbInput
                        type="text" disabled>
                <label class for="fPhone">Telefon</label>
              </div>
            </div>
<!--            <div class="col-2">-->
<!--              <div class="md-form">-->
<!--                <input class="form-control form-control-sm" formControlName="salutation" id="fSalutation"-->
<!--                       mdbInputDirective type="text" [validateSuccess]="false">-->
<!--                <label class for="fSalutation">Zwrot</label>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-6">-->
<!--              <div class="md-form ">-->
<!--                <input class="form-control form-control-sm" id="fJobTitle" value="{{customerContactItem.}}"-->
<!--                       type="text">-->
<!--                <label class for="fJobTitle">Tytuł zawodowy</label>-->
<!--              </div>-->
<!--            </div>-->
          </div>
<!--          <div class="row">-->
<!--            <div class="col-12">-->
<!--              <mdb-checkbox [checked]="true" [inline]="true" formControlName="active">Kontakt aktywny</mdb-checkbox>-->
<!--              <mdb-checkbox [checked]="true" [inline]="true" formControlName="verified">Kontakt zweryfikowany</mdb-checkbox>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card-body h-100">
          <h5 class="my-4 pb-2">Uprawnienia</h5>
          <ul class="text-left list-unstyled" >
            <li class="mb-2"><mdb-checkbox [checked]="customerContactItem.caseAccess" [disabled]="true">Zakładanie spraw</mdb-checkbox></li>
            <li class="mb-2"><mdb-checkbox [checked]="customerContactItem.updatesAccess" [disabled]="true">Pobieranie aktualizacji</mdb-checkbox></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card-body">
          <h5 class="mt-4 pb-2">Dane Klienta</h5>
          <div class="row">
            <div class="col-12">
              <div class="md-form mb-2">
                <input class="form-control form-control-sm" id="fName" value="{{customerContactItem.name}}" mdbInput
                       type="text" disabled>
                <label class for="fName">Nazwa</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="md-form mb-2">
                <input class="form-control form-control-sm" id="fZipCode" value="{{customerContactItem.zipCode}}" mdbInput
                       type="text" disabled>
                <label class for="fZipCode">Kod pcztowy</label>
              </div>
            </div>
            <div class="col-6">
              <div class="md-form mb-2">
                <input class="form-control form-control-sm" id="fCity" value="{{customerContactItem.city}}" mdbInput
                       type="text" disabled>
                <label class for="fCity">miejscowość</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="md-form mb-2">
                <input class="form-control form-control-sm" id="fStreet" value="{{customerContactItem.street}}" mdbInput
                       type="text" disabled>
                <label class for="fStreet">Ulica</label>
              </div>
            </div>
            <div class="col-6">
              <div class="md-form mb-2">
                <input class="form-control form-control-sm" id="fHouseNumber" value="{{customerContactItem.houseNumber}}" mdbInput
                       type="text" disabled>
                <label class for="fHouseNumber">Numer</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="md-form mb-2">
                <input class="form-control form-control-sm" id="fNip" value="{{customerContactItem.nip}}" mdbInput
                       type="text" disabled>
                <label class for="fNip">Nip</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="md-form mb-2">
                <input class="form-control form-control-sm" id="fMainPhone" value="{{customerContactItem.mainPhone}}" mdbInput
                       type="text" disabled>
                <label class for="fMainPhone">Telefon</label>
              </div>
            </div>
            <div class="col-6">
              <div class="md-form mb-2">
                <input class="form-control form-control-sm" id="fMainEmail" value="{{customerContactItem.mainEmail}}" mdbInput
                       type="text" disabled>
                <label class for="fMainPhone">Email</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

</div>


