import { Injectable } from '@angular/core';
import {ajax} from 'rxjs/ajax';
import {Observable} from "rxjs";
import {ListItem} from "../models/list-item";
import {map} from "rxjs/operators";
import {HttpClient} from '@angular/common/http';
import {FilterCache, SortingCache} from '../models/table';

@Injectable({
  providedIn: 'root'
})
export class TableSettingsService {
  private apiUrl = 'api/settings/table';

  constructor(private http: HttpClient) { }

  saveColumnSettings(tableCode, columnList) {
    return this.http.post(this.apiUrl + '/' + tableCode, columnList);
  }

  saveViewSettings(tableCode, view) {
    return this.http.post(this.apiUrl + '/' + tableCode, view);
  }

  getViews(tableCode): Observable<ListItem[]> {
    return this.http.get(this.apiUrl + '/' + tableCode + '/views').pipe(
      map((res) => res as ListItem[] || [])
    );
  }

  setRecordsPerPage(tableCode, count) {
    localStorage.setItem('table_' + tableCode + '_pageCount', count);
  }

  getRecordsPerPage(tableCode) {
    const result = localStorage.getItem('table_' + tableCode + '_pageCount');
    if (result) {
      return result;
    } else {
      return 10;
    }
  }

  setPage(tableCode, number) {
    localStorage.setItem('table_' + tableCode + '_pageNumber', number);
  }

  getPage(tableCode) {
    const result = localStorage.getItem('table_' + tableCode + '_pageNumber');
    if (result) {
      return result;
    } else {
      return 1;
    }
  }

  setSorting(tableCode, field, direction) {
    if (field && direction) {
      const sorting: SortingCache = {sort: field, sortDirection: direction};
      localStorage.setItem('table_' + tableCode + '_sorting', JSON.stringify(sorting));
    } else {
      localStorage.removeItem('table_' + tableCode + '_sorting');
    }
  }

  getSorting(tableCode) {
    const result = localStorage.getItem('table_' + tableCode + '_sorting');
    if (result) {
      return JSON.parse(result);
    } else {
      return null;
    }
  }

  setFiltering(tableCode: string, filters: FilterCache[]) {
    if (filters) {
      localStorage.setItem('table_' + tableCode + '_filtering', JSON.stringify(filters));
    } else {
      localStorage.removeItem('table_' + tableCode + '_filtering');
    }
  }

  getFiltering(tableCode): FilterCache[] {
    const result = localStorage.getItem('table_' + tableCode + '_filtering');
    if (result) {
      return JSON.parse(result);
    } else {
      return null;
    }
  }

  setFilteringExternal(tableCode: string, filters: object) {
    if (filters) {
      localStorage.setItem('table_' + tableCode + '_filteringExternal', JSON.stringify(filters));
    } else {
      localStorage.removeItem('table_' + tableCode + '_filteringExternal');
    }
  }

  getFilteringExternal(tableCode) {
    const result = localStorage.getItem('table_' + tableCode + '_filteringExternal');
    if (result) {
      return JSON.parse(result);
    } else {
      return null;
    }
  }

  getRequestParams(tableCode: string, includeFilter: boolean = true): string {
    let params = '';
    const pageParam = 'code=' + tableCode  // '&view=' +  // this.casesTable.view
      + '&paging.page=' + this.getPage(tableCode) + '&paging.recordsPerPage='
      + this.getRecordsPerPage(tableCode);
    params += pageParam;

    const sortParam: SortingCache = this.getSorting(tableCode);
    if (sortParam) {
      params += '&sort=' + sortParam.sort + '&sortDirection=' + sortParam.sortDirection;
    }

    const filterParam: FilterCache[] = this.getFiltering(tableCode);
    if (filterParam && includeFilter) {
      filterParam.forEach(item => params += '&filter.' + item.filterField + '=' + item.filterValue);
    }

    const filter = this.getFilteringExternal(tableCode);
    if (filter) {
      params = [params, ...Object.entries(filter).map(entry => `${entry[0]}=${entry[1]}`)].join('&');
    }

    return params;
  }
}
