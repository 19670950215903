import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DynamicTable} from '../shared/models/table';
import {map} from 'rxjs/operators';
import {MessageItem} from './models/message-item';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  private apiUrl = 'api/cp/messages';
  constructor(private http: HttpClient) { }

  getAll(parameters: string): Observable<DynamicTable> {
    if (parameters) {
      parameters = '?' + parameters;
    }
    return this.http.get(this.apiUrl + `/${parameters}`).pipe(
      map((res) => res as DynamicTable)
    );
  }

  getMessageById(id: number): Observable<MessageItem> {
    return this.http.get(this.apiUrl + `/${id}`).pipe(
      map((res) => res as MessageItem)
    );
  }

  getMessageByMailingActionId(id: number): Observable<MessageItem> {
    return this.http.get(this.apiUrl + `/mailingAction/${id}`).pipe(
      map((res) => res as MessageItem)
    );
  }
}
