import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {DynamicTable} from '../shared/models/table';
import {map} from 'rxjs/operators';
import {TrainingItem} from './models/training';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TrainingsService {
  private apiUrl = 'api/training';
  constructor(private http: HttpClient) { }

  getAvailableTrainings(parameters: string): Observable<DynamicTable> {
    if (parameters) {
      parameters = '?' + parameters;
    }
    return this.http.get(this.apiUrl + 's' + parameters).pipe(
      map((res) => res as DynamicTable)
    );
  }

  getTrainingById(id: number): Observable<TrainingItem> {
    return this.http.get(this.apiUrl + `/${id}`).pipe(
      map((res) => res as TrainingItem)
    );
  }

  // getTrainingApplicationById(id: number): Observable<TrainingApplicationItem> {
  //   return ajax(this.apiUrl + `/${id}`).pipe(
  //     map((res) => res.response as TrainingItem)
  //   );
  // }


  addApplication(applicationItem) {
    return this.http.post(this.apiUrl + '/application/add', applicationItem);
  }
  //
  // updateApplication(contactItem) {
  //   return ajax.put(this.apiUrl + '/update', contactItem, { 'Content-Type': 'application/json' });
  // }
  //
  // deleteApplication(id) {
  //   return ajax.delete(this.apiUrl + '/delete?' + id);
  // }
}
