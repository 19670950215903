import {Component, OnInit} from '@angular/core';
import {DynamicTable} from '../../../shared/models/table';
import {MDBModalRef} from 'ng-uikit-pro-standard';
import {UpdatesService} from '../../updates.service';
import {TableSettingsService} from '../../../shared/services/table-settings.service';


@Component({
  selector: 'app-history-list',
  templateUrl: './history-list.component.html'
})
export class HistoryListComponent implements OnInit {
  updatesTable: DynamicTable;
  updateViewStatus = 0;
  modalRef: MDBModalRef;

  constructor(private updatesService: UpdatesService,
              private tableSettingsService: TableSettingsService) {
  }

  ngOnInit() {
      const pageParam = 'paging.page=1&paging.recordsPerPage=10&firstRun=true';
      this.updatesService.getHistory(pageParam).subscribe((data) => {
        const result: any = data;
        this.updatesTable = result;
      });
  }

  reload() {
    const parameters = this.tableSettingsService.getRequestParams('ClientUpdatesHistoryList');
    this.updatesService.getHistory(parameters).subscribe((data) => {
      this.updatesTable = data;
    });
  }

  return(reload) {
    if (reload){
      this.reload();
    }
    this.updateViewStatus = 0;
  }
}
