import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdateListComponent } from './components/update-list/update-list.component';
import { UpdateShowComponent } from './components/update-show/update-show.component';
import {SharedModule} from '../shared/shared.module';
import { SoftwareMainVersionComponent } from './components/software-main-version/software-main-version.component';
import {CardsModule, MDBBootstrapModulePro, MDBBootstrapModulesPro} from 'ng-uikit-pro-standard';
import { SoftwareUpdateManualDialogComponent } from './components/modals/software-update-manual-dialog/software-update-manual-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { VersionListComponent } from './components/version-list/version-list.component';
import { HistoryListComponent } from './components/history-list/history-list.component';
import { FullViewComponent } from './components/full-view/full-view.component';



@NgModule({
  declarations: [UpdateListComponent, UpdateShowComponent, SoftwareMainVersionComponent, SoftwareUpdateManualDialogComponent, VersionListComponent, HistoryListComponent, FullViewComponent],
  imports: [
    CommonModule,
    SharedModule,
    CardsModule,
    MDBBootstrapModulePro,
    MDBBootstrapModulesPro,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class UpdatesModule { }
