import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import {BrowserModule} from '@angular/platform-browser';
import {MDBBootstrapModulesPro} from 'ng-uikit-pro-standard';
import {FormsModule} from '@angular/forms';
import { AttachmentComponent } from './components/attachment/attachment.component';

@NgModule({
  declarations: [FileUploaderComponent, AttachmentComponent],
  imports: [
    BrowserModule,
    MDBBootstrapModulesPro.forRoot(),
    CommonModule,
    FormsModule
  ],
  exports: [FileUploaderComponent, AttachmentComponent]
})
export class FilesModule { }
