import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TrainingItem} from '../../models/training';
import {AuthService} from '../../../auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TrainingsService} from '../../trainings.service';
import {forkJoin} from 'rxjs';
import {ToastService} from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-training-view',
  templateUrl: './training-view.component.html',
  styleUrls: ['./training-view.component.scss']
})
export class TrainingViewComponent implements OnInit {
  trainingItem: TrainingItem;
  isDataLoaded = false;
  addingApplication = false;
  @Input() refTrainingId = 0;
  @Output() returnClicked = new EventEmitter<any>();

  constructor(private trainingsService: TrainingsService,
              private authService: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private toastService: ToastService) { }

  ngOnInit() {
    const sTraining = this.trainingsService.getTrainingById(this.refTrainingId);
    forkJoin([sTraining]).subscribe(results => {
      this.trainingItem = results[0];
      this.isDataLoaded = true;
    });
  }

  return() {
    this.returnClicked.emit(true);
  }

  addApplication() {
    this.addingApplication = true;
  }

  cancelApplication() {
    this.addingApplication= false;
  }

  saveApplication(){
    this.refreshTraining();
    this.addingApplication = false;
    const options = { closeButton: true, tapToDismiss: false, positionClass: 'toast-bottom-right'};
    this.toastService.info('Rejestracja zgłoszenia zakończona powodzeniem', 'Potwierdzenie', options);
  }

  private refreshTraining() {
    this.trainingsService.getTrainingById(this.trainingItem.id).subscribe((data) => {
      const result: any = data;
      this.trainingItem = result;
    });
  }
}
