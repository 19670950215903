import { Component, OnInit } from '@angular/core';
import {CaseDashboard, PersonFullData, TrainingDashboard} from '../../models/dashboard';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../auth/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  profile: PersonFullData;
  caseList: CaseDashboard;
  trainingsList: TrainingDashboard;
  customerName: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService) { }

  ngOnInit() {
    this.caseList = this.route.snapshot.data['cases'];
   // this.trainingsList = this.route.snapshot.data['trainings'];
    this.customerName = this.authService.getCurrentUser().customer;
  }

  clickCaseEdit(id: number) {
    this.router.navigate([`/sprawy/${id}`]);
  }

}
