<div class="modal-content">
  <div class="modal-header white-text">
    <h4 class="modal-title w-100">{{ heading }}</h4>
  </div>
  <div class="modal-body">
    <h5 *ngIf="content.heading" style="white-space: pre-line" >{{content.heading}}</h5>
    <form *ngIf="content.name" [formGroup]="confirmForm">
      <div class="row">
        <div class="col-12">
          <div class="md-form mb-2">
            <textarea type="text" id="fName" class="md-textarea md-textarea-auto form-control" formControlName="name" rows="5" mdbInput required></textarea>
            <label for="fName">{{content.title}}</label>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn waves-light" *ngIf="showYesBtn" [ngClass]="'btn-'+ btnColor" (click)="clickYes(1)">
      {{yesBtnCaption}}
    </button>
    <button class="btn waves-light" *ngIf="showMiddleBtn" [ngClass]="'btn-'+ btnColor" (click)="clickYes(2)">
      {{middleBtnCaption}}
    </button>
    <button class="btn ml-auto" [ngClass]="'btn-outline-'+ btnColor" data-dismiss="modal" (click)="clickNo()">Anuluj
    </button>
  </div>
</div>

