<div class="view form-header p-2 mt-0 mb-0 pt-3  align-items-center">
  <h5 class="font-bold">{{customerName}}</h5>
  <div></div>
</div>
<div class="row">
  <div class="col-xl-12">
    <section class="my-5">
      <h2 class="h1-responsive font-weight-bold text-center my-4">Sprawy</h2>
      <div class="row mb-4">
        <div class="col-12 flex-center">
          <div class="chip text-white" style="background-color: rgba(3, 169, 244, 0.7); cursor: default">
            {{caseList.new}} - nowe
          </div>
          <div class="chip text-white" style="background-color: rgba(255, 152, 0, 0.7); cursor: default">
            {{caseList.assigned}} - przypisane
          </div>
          <div class="chip text-white" style="background-color: rgba(63, 81, 181, 0.7); cursor: default">
            {{caseList.confirmed}} - potwierdzone
          </div>
          <div class="chip text-white" style="background-color: rgba(76, 175, 80, 0.7); cursor: default">
            {{caseList.closingProposal}} - do zamknięcia
          </div>
          <div class="chip text-white" style="background-color: rgba(244, 67, 54, 0.7); cursor: default">
            {{caseList.canceled}} - anulowane
          </div>
          <div class="chip text-white" style="background-color: rgba(96, 125, 139, 0.7); cursor: default">
            {{caseList.closed}} - zamknięte
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-sm table-hover">
              <thead>
              <tr class="rgba-stylish-strong white-text small">
                <th>Status</th>
                <th>Krytyczność</th>
                <th>Data modyfikacji</th>
                <th>Tytuł</th>
                <th>Dotyczy</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let case of caseList.caseItems" (click)="clickCaseEdit(case.id)" class="clickable">
                <td class="text-white"><span [ngClass]="{'rgba-blue-strong': case.statusTypeId==1,
                  'rgba-orange-strong': case.statusTypeId==2,
                  'rgba-indigo-strong': case.statusTypeId==9 || case.statusTypeId==8 || case.statusTypeId==3,
                  'rgba-green-strong': case.statusTypeId==4,
                  'rgba-blue-grey-strong': case.statusTypeId==5,
                  'rgba-red-strong': case.statusTypeId==6}" class="badge">{{case.status}}</span></td>
                <td>{{case.criticality}}</td>
                <td>{{case.modificationDate}}</td>
                <td>{{case.title}}</td>
                <td>{{case.program}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <p>Liczba aktywnych zgłoszeń: <span class="badge purple text-white">{{caseList.numberOfItems}}</span>
          </p>
        </div>
      </div>

    </section>
<!--    <section class="my-5">-->
<!--      <h2 class="h1-responsive font-weight-bold text-center my-4">Dostępne szkolenia</h2>-->
<!--      &lt;!&ndash;<p class="text-center w-responsive mx-auto mb-5">Najnowsze wiadomości, komunikaty, aktualności.</p>&ndash;&gt;-->
<!--      <div class="row">-->
<!--        <div class="col-12">-->
<!--          <div class="table-responsive">-->
<!--            <table class="table table-sm">-->
<!--              <thead>-->
<!--              <tr class="rgba-stylish-strong white-text small">-->
<!--                <th>Temat</th>-->
<!--                <th>Od</th>-->
<!--                <th>Do</th>-->
<!--                <th>Wolne miejsca</th>-->
<!--                <th>Ilość dni</th>-->
<!--              </tr>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--              <tr *ngFor="let training of trainingsList.trainingItems">-->
<!--                <td>{{training.title}}</td>-->
<!--                <td>{{training.from}}</td>-->
<!--                <td>{{training.to}}</td>-->
<!--                <td><span [ngClass]="{'green':training.availableSeats>0, 'red': training.availableSeats==0}"-->
<!--                          class="badge">{{training.availableSeats}}</span></td>-->
<!--                <td>{{training.totalDays}}</td>-->
<!--              </tr>-->
<!--              </tbody>-->
<!--            </table>-->
<!--          </div>-->
<!--          <p>Liczba planowanych szkoleń: <span class="badge purple">{{trainingsList.numberOfItems}}</span>-->
<!--          </p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->
  </div>
<!--  <div class="col-xl-4">-->
<!--    <section class="my-5">-->
<!--      <h2 class="h1-responsive font-weight-bold text-center my-4">Aktualności</h2>-->
<!--      <p class="text-center w-responsive mx-auto mb-5">Najnowsze wiadomości, komunikaty, aktualności.</p>-->
<!--      <div class="row">-->
<!--        <div class="col-12">-->
<!--            <h6 class="green-text font-weight-bold mb-3"><i class="fas fa-image pr-2"></i>Promocje</h6>-->
<!--          <h3 class="font-weight-bold mb-3" (click)="message1.toggle()" style="cursor: pointer"><strong>Black Friday z GEO-INFO</strong><small> >></small></h3>-->
<!--          <div class="module-inner2" mdbCollapse #message1="bs-collapse">-->
<!--          <p><strong><span style="color: #ff0000;">BLACK FRIDAY - RABAT 40% *</span></strong></p>-->
<!--          <p><strong>DLA KLIENTÓW INDYWIDUALNYCH </strong></p>-->
<!--          <p><strong><span style="color: #ff0000;">OD 23.11.2018 R. DO 25.11.2018 R.</span></strong></p>-->
<!--          <p><strong>ZAKUP MODUŁÓW DO GEO-INFO MAPA ? RABAT 40% OD CENY JEDNOSTKOWEJ</strong></p>-->
<!--          <p><strong>ZAKUP LICENCJI GEO-INFO wMapa ? RABAT 40% OD CENY JEDNOSTKOWE</strong><strong>J</strong></p>-->
<!--          <p>(cena 1 licencji jednostanowiskowej w.Mapa z subskrypcją do 31.12.2019 r. w Promocji ? 13&nbsp;210,20 zł brutto)<br> Moduły funkcjonalne licencji GEO-INFO wMapa: Podstawowy, Analizy i Kontrole, Ewidencyjny, Uzbrojenia terenu, Danych Projektowych, Sytuacyjno-wysokościowy, Ośrodek, Obliczeniowo Projektowy, DXF, Raster, cz. opisowa EGiB, EGBiL, GIME</p>-->
<!--          <p><strong>ZAKUP LICENCJI GEO-INFO Delta PRO - RABAT 40% OD CENY JEDNOSTKOWEJ</strong></p>-->
<!--          <p><strong>(</strong>cena 1 licencji jednostanowiskowej Delta z subskrypcją do 31.12.2019 r. w Promocji ? 6&nbsp;044,22 zł brutto)</p>-->
<!--          <p><strong><br> </strong>- PROMOCJA wyłącznie dla Klientów indywidualnych<br> - rabaty nie sumują się<br> - faktury zakupowe będą wystawiane w piątek w godzinach pracy SYSTHERM INFO Sp.&nbsp;z&nbsp;o.o. oraz po niedzieli, po zakończeniu Promocji<br> - zamówienia można składać na adres <a href="mailto:sprzedaz@geo-info.pl">sprzedaz@geo-info.pl<br> </a>- wysyłka zrealizowanych zamówień po otrzymaniu wpłaty za fakturę<br>- <span style="color: #ff0000;">promocja nie dotyczy subskrypcji, aktualizacji i innych usług</span></p>-->
<!--        </div>-->
<!--          <p>2019-01-20</p>-->
<!--          <a mdbBtn class="btn-default" href="http://systherm-info.pl/wsparcie-uzytkownika/newsletter-menu/2018/black-friday"-->
<!--             target="_blank" rel="noopener noreferrer" mdbWavesEffect>Szczegóły</a>-->
<!--        </div>-->
<!--      </div>-->
<!--      <hr class="my-5">-->
<!--      <div class="row">-->
<!--        <div class="col-12">-->
<!--            <h6 class="pink-text font-weight-bold mb-3"><i class="far fa-image pr-2"></i>Nowa usługa</h6>-->
<!--          <h3 class="font-weight-bold mb-3" (click)="message2.toggle()" style="cursor: pointer"><strong>Nowa usługa</strong><small> >></small></h3>-->
<!--          <div class="module-inner2" mdbCollapse #message2="bs-collapse">-->
<!--            <p><strong>Nowa usługa</strong></p>-->
<!--            <p>Producent SIP GEO-INFO uruchomił specjalną usługę, w ramach której mogą zostać przeprowadzone na Państwa bazie danych następujące działania:</p>-->
<!--            <p>1.&nbsp;Dla obiektów:<br><strong>GUP_SD, GUS_SD - Studzienka, GUSIKP - Komora podziemna</strong><br>które mają relację do obiektów: '<strong>GUP_UB - Inne urządzenie techniczne bez symbolu</strong>' i&nbsp;jednocześnie do tego obiektu 'GUP_UB' istnieje relacja do włazu(ów) - '<strong>GUP_WZ</strong>, <strong>GUS_WZ</strong> - Właz' <br> to 'Włazy' te zostaną powiązane relacją z obiektami: '<strong>GUP_SD, GUS_SD - Studzienka</strong>' lub '<strong>GUSIKP - Komora podziemna'</strong>.</p>-->
<!--            <p>Dotychczasowa relacja "<strong>Właz</strong>" - '<strong>GUP_UB - Inne urządzenie techniczne bez symbolu</strong>' zostanie zachowana.</p>-->
<!--            <p>2.&nbsp;Jeśli dla obiektu '<strong>GUP_UB - Inne urządzenie techniczne bez symbolu</strong>' istnieje relacja do '<strong>Punktów o okr. wysokości GESUT</strong>' - Grupa 'Punkty wysokości GESUT'</p>-->
<!--            <p>to takie '<strong>Punkty o okr. wysokości GESUT</strong>' zostaną powiązane relacją z obiektami: '<strong>GUP_SD, GUS_SD - Studzienka'</strong> lub '<strong>GUSIKP - Komora podziemna</strong>', które są powiązane relacją z obiektem '<strong>GUP_UB - Inne urządzenie techniczne bez symbolu</strong>'.</p>-->
<!--            <p>Dotychczasowa relacja '<strong>Punkty wysokości GESUT</strong>' - '<strong>GUP_UB - Inne urządzenie techniczne bez symbolu</strong>' zostanie usunięta.<br><br>Powyższe działania spowodują zmiany w bazie danych, które są oczekiwane, gdy następuje wymiana danych z wykorzystaniem formatu GML, szczególnie podczas eksportu.</p>-->
<!--            <p>Zainteresowane Ośrodki prosimy o kontakt z działem sprzedaży w celu uzyskania oferty.</p>-->
<!--            <p><a href="mailto:sprzedaz@systherm-info.pl"><span style="text-decoration: underline;">sprzedaz@systherm-info.pl</span></a></p>-->
<!--          </div>-->
<!--          <p>2018-12-30</p>-->
<!--          <a mdbBtn class="btn-default" href="http://systherm-info.pl/wsparcie-uzytkownika/newsletter-menu/2018/012018/nowa-usuga"-->
<!--             target="_blank" rel="noopener noreferrer" mdbWavesEffect>Szczegóły</a>-->
<!--        </div>-->
<!--      </div>-->

<!--      <hr class="my-5">-->
<!--      <div class="row">-->
<!--        <div class="col-12">-->
<!--            <h6 class="indigo-text font-weight-bold mb-3"><i class="fas fa-suitcase pr-2"></i>Nowości</h6>-->
<!--          <h3 class="font-weight-bold mb-3" (click)="message3.toggle()" style="cursor: pointer"><strong>Elektroniczne zgłoszenie uczestnictwa w szkoleniu</strong><small> >></small></h3>-->
<!--          <div class="module-inner2" mdbCollapse #message3="bs-collapse">-->
<!--            <p>Informujemy, że uruchomiliśmy możliwość elektronicznej rejestracji uczestnictwa w szkoleniach z zakresu naszych produktów. Zaletą korzystania z tego rozwiązania jest możliwość samodzielnego znalezienia pasującego terminu i dokonania rejestracji o dowolnej porze, bez konieczności telefonicznego kontaktu z nami.</p>-->
<!--            <p>Więcej szczegółów oraz dostęp do formularzy znajduje się <span style="text-decoration: underline;"><a href="http://www.geo-info.pl/uslugi/szkolenia#3">TUTAJ</a></span>.</p>-->
<!--          </div>-->
<!--          <p>2018-12-12</p>-->
<!--          <a mdbBtn class="btn-default" href="http://systherm-info.pl/wsparcie-uzytkownika/newsletter-menu/2018/012018/rejestracja-online"-->
<!--             target="_blank" rel="noopener noreferrer" mdbWavesEffect>Szczegóły</a>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->
<!--  </div>-->
</div>


