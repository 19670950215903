import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {PasswordChangeItem} from '../../models/password';
import {AuthService} from '../../auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastService} from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  passwordForm: UntypedFormGroup;
  passwordItem: PasswordChangeItem;
  guid: string;

  constructor(private formBuilder: UntypedFormBuilder,
              private authService: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private toastService: ToastService) {
  }

  ngOnInit() {
    this.passwordForm = this.buildPasswordForm();
    this.guid = this.route.snapshot.queryParams['guid'];
  }

  buildPasswordForm() {
    return this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      newPassword2: ['', Validators.required]
    });
  }

  submit() {
    this.passwordItem = Object.assign({}, this.passwordForm.value);
    this.authService.resetPassword(this.guid, this.passwordItem.newPassword)
      .subscribe(
        data => {
          const options = {closeButton: true, tapToDismiss: false, positionClass: 'md-toast-bottom-full-width'};
          this.toastService.info('hasło zostało zmienione', 'Potwierdzenie', options);
          this.router.navigate(['/logowanie']);
        },
        error => {
          const options = { closeButton: true, tapToDismiss: false, positionClass: 'md-toast-bottom-full-width'};
          this.toastService.error('Wystąpił błąd podczas resetowania hasła', 'Błąd', options);
        });
  }
}
