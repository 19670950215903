import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentationListComponent } from './components/documentation-list/documentation-list.component';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {MDBBootstrapModulesPro} from 'ng-uikit-pro-standard';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {DocumentationRoutingModule} from './documentation-routing.module';
import {DocumentationResolve} from './documentation-resolve-service';

@NgModule({
  declarations: [DocumentationListComponent],
  imports: [
    BrowserModule,
    CommonModule,
    DocumentationRoutingModule,
    FormsModule,
    HttpClientModule,
    MDBBootstrapModulesPro.forRoot(),
    ReactiveFormsModule,
    RouterModule,
    SharedModule
  ],
  providers: [DocumentationResolve]
})
export class DocumentationModule { }
