import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DynamicTable} from '../../../shared/models/table';
import {ActivatedRoute, Router} from '@angular/router';
import {DocumentationService} from '../../documentation.service';
import {DocumentationFile} from '../../models/documentation-item';
import {FilesService} from '../../../files/files.service';
import {BytesPipe} from 'angular-pipes';
import {ToastService} from 'ng-uikit-pro-standard';
import {FileSizePipe} from '../../../shared/pipes/file-size.pipe';

@Component({
  selector: 'app-documentation-list',
  templateUrl: './documentation-list.component.html',
  styleUrls: ['./documentation-list.component.scss']
})
export class DocumentationListComponent implements OnInit {
  documentationTable: DynamicTable;
  documentationId = 0;
  documentationViewStatus = 0;
  documentationFiles: DocumentationFile[];
  @ViewChild('filesModal') filesModal: any;

  constructor(private documentationService: DocumentationService,
              private route: ActivatedRoute,
              private router: Router,
              private filesService: FilesService,
              private toastService: ToastService) {
  }

  ngOnInit() {
    // if (this.refCustomerId === 0) {
    this.documentationTable = this.route.snapshot.data['documentation'];
    // console.log(this.documentationViewStatus);
    // } else {
    //   const pageParam = 'paging.page=1&paging.recordsPerPage=10';
    //
    //   this.documentationService.getDocumentation(pageParam).subscribe((data) => {
    //     const result: any = data;
    //     this.documentationTable = result;
    //   });
    // }
  }

  documentationReload() {
    const pageParam = 'code=' + this.documentationTable.code + '&view=' + this.documentationTable.view
      + '&paging.page=' + this.documentationTable.paging.page + '&paging.recordsPerPage='
      + this.documentationTable.paging.recordsPerPage;
    let filterParam = '';
    this.documentationTable.heads.forEach(item => {
      if (item.filterValue) {
        filterParam += '&filter.' + item.filterField + '=' + item.filterValue;
      }
    });
    let sortParam = '';
    const sortField = this.documentationTable.heads.find(item => item.sortingDirection > 0);
    if (sortField) {
      sortParam = '&sort=' + sortField.name + '&sortDirection=' + sortField.sortingDirection;
    }
    this.documentationService.getDocumentation(pageParam + filterParam + sortParam).subscribe((data) => {
      const result: any = data;
      this.documentationTable.paging = result.paging;
      this.documentationTable.rows = result.rows;
    });
  }


  goToDetails(id) {
    this.documentationId = id;
    this.documentationService.getDocumentationFiles(id).subscribe(
      data => {
        const result: any = data;
        this.documentationFiles = result;
        this.filesModal.show();
      },
      error => {
        this.toastService.error('Wystąpił bład podczas pobierania plików dokumentacji.', 'Błąd');
      });
  }

  downloadFile(name: string) {
    const fileList: string[] = [name];
    this.filesService.downloadDocumentation(this.documentationId, 'single', fileList);
  }

  download() {
    this.filesService.downloadDocumentation(this.documentationId, 'all');
  }

  adddocumentation() {
    this.router.navigate(['/dokumentacja/dodaj']);
  }

  submitdocumentation() {

  }

  canceldocumentation() {
    this.router.navigate(['/dokumentacja']);
  }

  documentationAdd() {
    this.documentationId = -1;
    this.documentationViewStatus = 1;
  }

  documentationEdit(id) {

    this.documentationId = id;
    this.documentationViewStatus = 2;
  }

  documentationReturn(reload) {
    if (reload) {
      this.documentationReload();
    }
    this.documentationViewStatus = 0;

  }
}
