<div class="row justify-content-between">
  <div class="col">
    <ul class="pagination mb-0">
      <li class="page-item" [ngClass]="{'disabled': paging.page === 1}">
        <button class="page-link" tabindex="-1" (click)="onPage(1)" role="button"><<</button>
      </li>
      <li class="page-item" [ngClass]="{'disabled': paging.page === 1}">
        <button class="page-link" tabindex="-1" (click)="onPrev()" role="button"><</button>
      </li>
      <li *ngFor="let pageNum of getPages()" class="page-item" >
        <button class="page-link" (click)="onPage(pageNum)" role="button" [ngClass]="{'btn-default text-white': pageNum === paging.page}">{{pageNum}}</button>
      </li>
      <li class="page-item" [ngClass]="{'disabled': paging.page === paging.pagesCount}">
        <button class="page-link" (click)="onNext()" role="button">></button>
      </li>
      <li class="page-item" [ngClass]="{'disabled': paging.page === paging.pagesCount}">
        <button class="page-link" (click)="onPage(paging.pagesCount)" role="button">>></button>
      </li>
      <li class="page-item">
        <span class="page-link" disabled>{{ paging.from }} - {{ paging.to }} z {{paging.recordsCount}}
        </span>
      </li>
    </ul>
  </div>

  <div *ngIf="showPageChoose" class="md-form input-group mb-0 mt-0  d-none d-sm-flex" style="width: 100px">
    <input type="text" class="form-control" style="background-color: white;" [(ngModel)]="currentSetPage" (change)="setPage()">
    <div class="input-group-append">
      <button mdbBtn color="default" size="sm" class="m-0 mr-2 px-3 py-2" type="button" id="button-addon1" title="Idź do strony" placement="right"
              mdbWavesEffect (click)="setPage()"><i class="fa  fa-caret-right mt-0" ></i></button>
    </div>
  </div>
  <div *ngIf="showPageChoose" class="md-form input-group mb-0 mt-0 d-none d-md-flex" style="width: 100px">
    <input type="text" class="form-control" style="background-color: white;" [(ngModel)]="currentRecordsPerPage" (change)="changePageSize()">
    <div class="input-group-append">
      <button mdbBtn color="default" size="sm" class="m-0 mr-2 px-3 py-2" type="button" id="button-addon2" title="Rekordów na stronę" placement="right"
              mdbWavesEffect (click)="changePageSize()"><i class="fa fa-list-ol mt-0"></i></button>
    </div>
  </div>
  <div>
  </div>
</div>


