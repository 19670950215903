<div class="row">
  <mdb-card class="text-white mb-3 ml-3" bgColor="bg-info" style="max-width: 20rem">
    <mdb-card-header class="info-color">Wersja Mapa</mdb-card-header>
    <mdb-card-body>
      <mdb-card-title>
        <h5 class="text-center">{{softwareMainVersionItem?.versionMapa}}</h5>
      </mdb-card-title>
    </mdb-card-body>
  </mdb-card>
  <mdb-card class="text-white mb-3 ml-3" bgColor="bg-info" style="max-width: 20rem">
    <mdb-card-header class="info-color">Wersja Ośrodek</mdb-card-header>
    <mdb-card-body>
      <mdb-card-title>
        <h5 class="text-center">{{softwareMainVersionItem?.versionOsrodek}}</h5>
      </mdb-card-title>
    </mdb-card-body>
  </mdb-card>
  <mdb-card class="text-white mb-3 ml-3" bgColor="bg-info" style="max-width: 20rem">
    <mdb-card-header class="info-color">Data weryfikacji</mdb-card-header>
    <mdb-card-body>
      <mdb-card-title>
        <h5 class="text-center">{{softwareMainVersionItem?.versionCheckDate}}</h5>
      </mdb-card-title>
    </mdb-card-body>
  </mdb-card>
  <button mdbBtn mdbRippleRadius
          class="btn btn-default btn-sm waves-light mt-auto ml-3" style="margin-bottom: 17px;"
          (click)="refreshClick()"
  >{{buttonMessage}}
  </button>
</div>


