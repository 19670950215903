import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DynamicTable} from '../shared/models/table';
import {map} from 'rxjs/operators';
import {SoftwareMainVersionItem} from './models/software-main-version-item';
import {SoftwareUpdateManualDef} from './models/software-update-manual-def';

@Injectable({
  providedIn: 'root'
})
export class UpdatesService {
  private apiUrl = 'api/cp/softwareUpdates/client';
  constructor(private http: HttpClient) { }

  getIsEnabled(): Observable<boolean> {
    return this.http.get(this.apiUrl + '/isEnabled').pipe(
      map((res) => res as boolean)
    );
  }

  getInProgress(parameters: string): Observable<DynamicTable> {
    return this.http.get(this.apiUrl + '/inProgress?' + parameters).pipe(
      map((res) => res as DynamicTable)
    );
  }

  getHistory(parameters: string): Observable<DynamicTable> {
    return this.http.get(this.apiUrl + '/history?' + parameters).pipe(
      map((res) => res as DynamicTable)
    );
  }

  getAvailable(parameters: string): Observable<DynamicTable> {
    return this.http.get(this.apiUrl + '/getAvailable?' + parameters).pipe(
      map((res) => res as DynamicTable)
    );
  }

  getSoftwareMainVersion(forceRefresh: boolean): Observable<SoftwareMainVersionItem> {
    return this.http.get(this.apiUrl + `/softwareMainVersion/` + (forceRefresh ? '?forceRefresh=true' : '') ).pipe(
      map((res) => res as SoftwareMainVersionItem)
    );
  }

  getForManualUpdate(customerId: number): Observable<SoftwareUpdateManualDef> {
    return this.http.get(this.apiUrl + `/definition/getForManual`).pipe(
      map((res) => res as SoftwareUpdateManualDef)
    );
  }

  setManualUpdate(update: SoftwareUpdateManualDef) {
    return this.http.post(this.apiUrl + `/setManualUpdate`, update);
  }
}
