import {Route, RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {TrainingListComponent} from './components/training-list/training-list.component';
import {TrainingsResolve} from './trainings-resolve.service';

const TRAININGS: Route[] = [
  {
    path: 'szkolenia',
     // component: <any>TrainingListComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: <any>TrainingListComponent,
        resolve: {
          trainings: TrainingsResolve
        }
      },
    ]
  }
]

@NgModule({
  imports: [
    RouterModule.forChild(TRAININGS)
  ],
  exports: [
    RouterModule
  ]
})

export class TrainingsRoutingModule {}
