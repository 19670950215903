<div class="row">
  <div class="col-12">
    <app-table-main *ngIf="licencesTable"
                    (reload)="licencesReload()"
                    (selectedIdChange)="clickDetails($event)"
                    tableCaption="Licencje"
                    [dynamicTable]="licencesTable">
    </app-table-main>
  </div>
</div>
<ng-template #licencePreview>
  <div class="row">
    <div class="col-12">
      <app-licence-add-edit
        [refLicenceId]="licenceId"
        (returnClicked)="licenceReturn($event)"
        [isAdd]="licenceId === -1 ? true : false"
      >
      </app-licence-add-edit>
    </div>
  </div>
</ng-template>



