import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageListComponent } from './components/message-list/message-list.component';
import { MessageShowComponent } from './components/message-show/message-show.component';
import {MessagesResolve} from './messages-resolve.service';
import {SharedModule} from '../shared/shared.module';



@NgModule({
  declarations: [
    MessageListComponent,
    MessageShowComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  providers: [MessagesResolve]
})
export class MessagesModule { }
