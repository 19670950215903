import { Component, OnInit } from '@angular/core';
import {MDBModalRef} from 'ng-uikit-pro-standard';
import {DynamicTable} from '../../../../shared/models/table';
import {forkJoin, Subject} from 'rxjs';
import {LicencesService} from '../../../licences.service';
import {FilesService} from '../../../../files/files.service';

@Component({
  selector: 'app-update-list-dialog',
  templateUrl: './update-list-dialog.component.html',
})
export class UpdateListDialogComponent implements OnInit {
  heading: string;
  content: any;
  action: Subject<any> = new Subject();
  updatesTable: DynamicTable;
  licenceId: number;
  isNotAvailable: boolean;
  isDataLoaded = false;

  constructor(public modalRef: MDBModalRef,
              private licencesService: LicencesService,
              private filesService: FilesService) { }

  ngOnInit() {
    forkJoin([this.licencesService.getUpdatesByLicenceId(this.licenceId)])
      .subscribe(results => {
        this.updatesTable = results[0];
        if (this.updatesTable.rows.length === 0) { this.isNotAvailable = true; }
        this.isDataLoaded = true;
      });
  }

  clickNo() {
    this.action.next({ status: false });
  }

  clickChooseCustomer(id: number) {
    this.downloadFile(id);
  }

  downloadFile(id: number) {
    this.filesService.downloadUpdate(id);
  }
}
