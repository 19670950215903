import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {NotificationCaseItem} from './models/notifications';
import {ICustomerContact} from './models/account';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private apiUrl = 'api/settings';
  constructor(private http: HttpClient) { }

  getNotificationCase(): Observable<NotificationCaseItem> {
    return this.http.get(this.apiUrl + '/notification/case').pipe(
      map((res) => res as NotificationCaseItem)
    );
  }

  updateNotificationCase(notificationCaseItem) {
    return this.http.put(this.apiUrl + '/notification/case', notificationCaseItem);
  }

  getCustomerContact(): Observable<ICustomerContact> {
    return this.http.get(this.apiUrl + '/customerContact').pipe(
      map((res) => res as ICustomerContact)
    );
  }
}
