<div *ngIf="isEnabled">
  <app-software-main-version></app-software-main-version>

  <div class="row mb-3">
    <div class="col">
      <div class="form-check form-check-inline">
        <input type="radio" [(ngModel)]="viewType" value="typeVersion" name="updateRadioView"
               class="form-check-input" id="viewType5" mdbInput>
        <label class="form-check-label" for="viewType5">Dostępne wersje</label>
      </div>
      <div class="form-check form-check-inline">
        <input type="radio" [(ngModel)]="viewType" value="typeUpdate" name="updateRadioView"
               class="form-check-input" id="viewType1" mdbInput>
        <label class="form-check-label" for="viewType1">Aktualizacje w toku</label>
      </div>
      <div class="form-check form-check-inline">
        <input type="radio" [(ngModel)]="viewType" value="typeHistory" name="updateRadioView"
               class="form-check-input" id="viewType2" mdbInput>
        <label class="form-check-label" for="viewType2">Historia aktualizacji</label>
      </div>
    </div>
  </div>

  <ng-template [ngIf]="viewType==='typeVersion'">
    <app-version-list (startUpdate)="startUpdate()"></app-version-list>
  </ng-template>
  <ng-template [ngIf]="viewType==='typeUpdate'">
    <app-update-list></app-update-list>
  </ng-template>
  <ng-template [ngIf]="viewType==='typeHistory'">
    <app-history-list></app-history-list>
  </ng-template>

</div>
<div *ngIf="!isEnabled && isDataLoaded">
  <div class="view form-header p-2 mt-0 mb-0 d-flex justify-content-between align-items-center">
    <div>
    </div>
    <h5 class="font-bold">{{'Moduł aktualizacji nie został skonfigurowany'}}</h5>
    <div></div>
  </div>
  <div class="row">
    <div class="col">
      <div class="card-body h-100">
        <h5>Skontaktuj się z administratorem systemu w celu aktywacji aktualizacji oprogramowania.</h5>
      </div>
    </div>
  </div>

</div>
