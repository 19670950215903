<div class="container">
  <div class="row">
    <div class="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-lg-5">
      <div class="card wow fadeIn" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.6s;">
        <div class="card-body">
          <div class="form-header light-blue darken-3 white-text">
            <h3>Panel Klienta Systherm-Info</h3>
          </div>
          <form [formGroup]="loginForm">
            <div class="md-form">
              <i class="fa fa-user prefix"></i>
              <input class="form-control" id="flogin" type="text" formControlName="login" mdbInput mdbValidate data-error="Nazwa użytkownika jest wymagana" [validateSuccess]="false">
              <label class for="flogin">Nazwa użytkownika</label>
            </div>
            <div class="md-form">
              <i class="fa fa-lock prefix"></i>
              <input class="form-control" id="fpassword" type="password" formControlName="password" mdbInput mdbValidate data-error="Hasło jest wymagane" [validateSuccess]="false">
              <label class for="fpassword">Hasło</label>
            </div>
            <p class="font-small blue-text d-flex justify-content-end"><a [routerLink]="['/reset-hasla-zgloszenie']" class="blue-text ml-1">Nie pamiętam hasła</a>
            </p>
            <div class="text-center">
              <button class="btn btn-default waves-light" (click)="onSubmmit()" [disabled]="loginForm.invalid" mdbRippleRadius>Zaloguj</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
