<div class="container">
  <div class="row">
    <div class="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-lg-5">
      <div class="card wow fadeIn" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s;">
        <div class="card-body">
          <div class="form-header light-blue darken-3">
            <h3>Panel Klienta Systherm-Info</h3>
          </div>
          <form [formGroup]="passwordForm">
            <h4>Reset hasła</h4>
            <div class="md-form">
              <input class="form-control" id="fnewPassword" type="password" formControlName="newPassword"
                     mdbInput mdbValidate data-error="Wypełnij pole. Wymagana liczna znaków co najmniej 5" [validateSuccess]="false">
              <label class for="fnewPassword">Nowe hasło</label>
            </div>
            <div class="md-form">
              <input class="form-control" id="fnewPassword2" type="password" formControlName="newPassword2" pattern="{{ passwordForm.controls.newPassword.value }}"
                     mdbInput mdbValidate data-error="Nowe hasło i Powtórzone hasło różnią się" [validateSuccess]="false">
              <label class for="fnewPassword2">Powtórzone hasło</label>
            </div>
            <div class="text-center">
              <button class="btn btn-default waves-light" (click)="submit()" [disabled]="passwordForm.invalid" mdbRippleRadius>Zapisz</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

