import {Route, RouterModule} from '@angular/router';
import {AuthGuard} from '../auth/guard/auth.guard';
import {NgModule} from '@angular/core';
import {ContactListComponent} from './components/contact-list/contact-list.component';
import {ContactAddComponent} from './components/contact-add/contact-add.component';
import {ContactEditComponent} from './components/contact-edit/contact-edit.component';
import {ContactsResolve} from './administration-resolve.service';

const ADMINISTRATION: Route[] = [
  {
    path: 'administracja/uzytkownicy',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: <any>ContactListComponent,
        resolve: {
          contacts: ContactsResolve
        }
      },
      {
        path: 'dodaj',
        component: <any>ContactAddComponent
      },
      {
        path: 'edycja/:id',
        component: <any>ContactEditComponent,
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(ADMINISTRATION)
  ],
  exports: [
    RouterModule
  ]
})

export class AdministrationRoutingModule {}
