import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-loader-dialog',
  templateUrl: './loader-dialog.component.html',
})
export class LoaderDialogComponent implements OnInit {
  @Input() header = 'Praca w toku';
  @Input() content: string;
  @Input() ready = false;
  constructor() { }

  ngOnInit(): void {
  }

}
