import {Route, RouterModule} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {ChangePasswordComponent} from './components/change-password/change-password.component';
import {AuthGuard} from './guard/auth.guard';
import {NgModule} from '@angular/core';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {ResetPasswordAttemptComponent} from './components/reset-password-attempt/reset-password-attempt.component';

const AUTH: Route[] = [
  {
    path: 'logowanie',
    component: LoginComponent,
  },
  {
    path: 'zmiana-hasla',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reset-hasla-zgloszenie',
    component: ResetPasswordAttemptComponent,
  }
  ,
  {
    path: 'reset-hasla',
    component: ResetPasswordComponent,
  }
]

@NgModule({
  imports: [
    RouterModule.forChild(AUTH)
  ],
  exports: [
    RouterModule
  ]
})

export class AuthRoutingModule {}
