import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CaseComment, CaseItem, CaseUpdateItem} from '../../models/case';
import {CasesService} from '../../cases.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalDirective, ToastService} from 'ng-uikit-pro-standard';
import {CacheService} from '../../../shared/services/cache.service';
import {ListService} from '../../../shared/services/list.service';
import {forkJoin} from 'rxjs';
import {UntypedFormBuilder, FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ClipboardService} from 'ngx-clipboard';
import {ListCodePipe} from '../../../shared/pipes/list-code.pipe';
import {ToolsService} from '../../../shared/services/tools.service';

@Component({
  selector: 'app-case-edit',
  templateUrl: './case-edit.component.html',
  styleUrls: ['./case-edit.component.scss']
})
export class CaseEditComponent implements OnInit {
  caseForm: UntypedFormGroup;
  caseItem: CaseItem;
  comments: CaseComment[];
  addingComment = false;
  resumingCase = false;
  @Input() refCaseId = 0;
  @Input() callFromCases = false;
  @Output() returnClicked = new EventEmitter<any>();
  serviceWorkers: any;
  productSelect: Array<any>;
  criticalitySelect: Array<any>;
  criticalitySelectFull: Array<any>;
  sourceSelect: Array<any>;
  typeSelect: Array<any>;
  statusSelect: Array<any>;
  isDataLoaded = false;
  closingMode = 0;
  caseToUpdate: CaseUpdateItem;
  inProgress = false;

  @ViewChild('editModal') public showModalOnClick: ModalDirective;

  constructor(private casesService: CasesService,
              private toolsService: ToolsService,
              private route: ActivatedRoute,
              private router: Router,
              private toastService: ToastService,
              private cacheService: CacheService,
              private listService: ListService,
              private formBuilder: UntypedFormBuilder,
              private clipboardService: ClipboardService) {
  }

  ngOnInit() {
    if (!this.refCaseId) {
      this.refCaseId = this.route.snapshot.params.id;
    }
    forkJoin([this.listService.serviceWorkers(), this.listService.programCP(), this.listService.caseTypes(),
      this.listService.caseCriticalities(), this.listService.caseSources(), this.listService.caseStatuses(),
      this.casesService.getCaseById(this.refCaseId), this.casesService.getCaseByIdComments(this.refCaseId)])
      .subscribe(results => {
        this.serviceWorkers = results[0];
        this.productSelect = results[1];
        this.typeSelect = results[2];
        this.criticalitySelectFull = results[3];
        this.sourceSelect = results[4];
        this.statusSelect = results[5];
        this.caseItem = results[6];
        this.comments = results[7];
        this.getSelectedTypeValue(this.caseItem.requestTypeId.toString());
        this.caseForm = this.buildCaseForm();
        this.isDataLoaded = true;
      });
  }

  public showEditModal(): void {
    this.getSelectedTypeValue(this.caseItem.requestTypeId.toString());
    this.showModalOnClick.show();
  }

  buildCaseForm() {
    return this.formBuilder.group({
      requestTypeId: [this.caseItem.requestTypeId !== 0 ? this.caseItem.requestTypeId.toString() : '', Validators.required],
      criticalityId: [this.caseItem.criticalityId !== 0 ? this.caseItem.criticalityId.toString() : '', Validators.required],
      clientNumber: [this.caseItem.clientNumber],
    });
  }

  returnToList() {
    this.router.navigate(['/sprawy']);
  }

  addComment(code) {
    if (code === '4') {
      this.resumingCase = true;
    }
    this.addingComment = true;
  }

  cancelComment() {
    this.addingComment = false;
  }

  saveComment() {
    if (this.resumingCase) {
      this.casesService.resumeCase(this.caseItem.id).subscribe((data) => {
      });
    }
    this.refreshCase();
    this.addingComment = false;
    const options = {closeButton: true, tapToDismiss: false, positionClass: 'md-toast-bottom-full-width'};
    this.toastService.info('Komentarz został zapisany', 'Potwierdzenie', options);
  }

  switchAttachment(id: number) {
    const comment = this.comments.find(x => x.id === id);
    comment.showAttachments = !comment.showAttachments;
  }

  closeCase() {
    if (this.closingMode === 0) {
      this.casesService.closeCase(this.caseItem.id).subscribe((data) => {
        this.refreshCase();
      });
    } else {
      this.casesService.cancelCase(this.caseItem.id).subscribe((data) => {
        this.refreshCase();
      });
    }
  }

  private refreshCase() {
    this.casesService.getCaseByIdComments(this.caseItem.id).subscribe((data) => {
      const result: any = data;
      this.comments = result;
    });
    this.casesService.getCaseById(this.caseItem.id).subscribe((data) => {
      const result: any = data;
      this.caseItem = result;
    });
  }

  return() {
    if (this.callFromCases) {
      this.returnClicked.emit();
    } else {
      this.returnToList();
    }
  }

  setClosingMode(mode) {
    this.closingMode = mode;
  }

  goToLink(url) {
    window.open(url, '_blank');
  }

  copyText(text: string) {
    this.clipboardService.copyFromContent(text);
  }

  getSelectedTypeValue(event: any) {
    this.criticalitySelect = this.criticalitySelectFull.filter((el) => el.parent === event);
  }

  saveUpdateCase() {
    if (!this.inProgress) {
      this.inProgress = true;
      if (this.caseForm.valid) {
        this.caseToUpdate = Object.assign({}, this.caseForm.value);
        this.casesService.updateCase(this.caseItem.id, this.caseToUpdate)
          .subscribe(
            data => {
              this.showModalOnClick.hide();
              this.refreshCase();
              const options = {closeButton: true, tapToDismiss: false, positionClass: 'md-toast-bottom-full-width'};
              this.toastService.info('Sprawa została zaktualizowana', 'Potwierdzenie', options);
              this.inProgress = false;
            },
            error => {
              const options = {closeButton: true, tapToDismiss: false, positionClass: 'md-toast-bottom-full-width'};
              this.toastService.error('Wystąpił błąd podczas aktualizacji danych sprawy', 'Błąd', options);
              this.inProgress = false;
            });
      } else {
        this.toolsService.validateAllFormFields(this.caseForm);
        this.inProgress = false;
      }
    }
  }
}

