import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {DynamicTable} from '../shared/models/table';
import {CaseComment, CaseItem} from './models/case';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CasesService {
  private apiUrl = 'api/cp/case';

  constructor(private http: HttpClient) { }

  getCases(parameters: string): Observable<DynamicTable> {
    if (parameters) {
      parameters = '?' + parameters;
    }
    return this.http.get(this.apiUrl + 'sCustomer' + parameters).pipe(
      map((res) => res as DynamicTable)
    );
  }

  getCaseById(id: number): Observable<CaseItem> {
    return this.http.get(this.apiUrl + `/${id}/customer`).pipe(
      map((res) => res as CaseItem)
    );
  }

  getCaseByIdComments(id: number): Observable<CaseComment[]> {
    return this.http.get(this.apiUrl + `/${id}` + '/comments/customer').pipe(
      map((res) => res as CaseComment[] || [])
    );
  }

  addComment(id, comment) {
    return this.http.post(this.apiUrl + '/' + id + '/comment/add', comment);
  }

  addCase(caseItem) {
    return this.http.post(this.apiUrl + '/add', caseItem);
  }

  closeCase(id) {
    return this.http.post(this.apiUrl + `/${id}` + '/close', '');
  }

  cancelCase(id){
    return this.http.post(this.apiUrl + `/${id}` + '/cancel', '');
  }

  resumeCase(id) {
    return this.http.get(this.apiUrl + `/${id}` + '/resume');
  }

  updateCase(id, caseUpdateItem) {
    return this.http.post(this.apiUrl + `/${id}` +  '/updateCP', caseUpdateItem);
  }

  exportCases(parameters: string) {
    if (parameters) {
      parameters = '?' + parameters;
    }
    window.open(this.apiUrl + 'sCustomer/export' + parameters);
  }
}
