import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DynamicTable} from '../../../shared/models/table';
import {MDBModalRef, MDBModalService, ToastService} from 'ng-uikit-pro-standard';
import {UpdatesService} from '../../updates.service';
import {TableSettingsService} from '../../../shared/services/table-settings.service';
import {SoftwareUpdateManualDialogComponent} from '../modals/software-update-manual-dialog/software-update-manual-dialog.component';

@Component({
  selector: 'app-version-list',
  templateUrl: './version-list.component.html'
})
export class VersionListComponent implements OnInit {
  updatesTable: DynamicTable;
  updateViewStatus = 0;
  @Input() refCustomerId = 0;
  @Output() startUpdate = new EventEmitter<any>();
  modalRef: MDBModalRef;

  constructor(private updatesService: UpdatesService,
              private tableSettingsService: TableSettingsService,
              private modalService: MDBModalService,
              private toastService: ToastService) {
  }

  ngOnInit() {
    const pageParam = 'paging.page=1&paging.recordsPerPage=10';
    this.updatesService.getAvailable(pageParam).subscribe((data) => {
      const result: any = data;
      this.updatesTable = result;
    });
  }

  reload() {
    const parameters = this.tableSettingsService.getRequestParams('ClientUpdatesVersionList');
    this.updatesService.getAvailable(parameters).subscribe((data) => {
      this.updatesTable = data;
    });
  }

  return(reload) {
    if (reload){
      this.reload();
    }
    this.updateViewStatus = 0;
  }

  clickUpdateManual() {
    const modalOptions = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog modal-full-height modal-top w-50 mx-auto',
      containerClass: '',
      animated: true,
      data: {
        refCustomerId: this.refCustomerId,
      }
    };
    this.modalRef = this.modalService.show(SoftwareUpdateManualDialogComponent, modalOptions);
    this.modalRef.content.action.subscribe((result: any) => {
        if (result.status) {
          this.updatesService.setManualUpdate(result.definitionItem).subscribe(
            () => {
              const options = {closeButton: true, tapToDismiss: true, progressBar: true, positionClass: 'md-toast-bottom-right'};
              this.toastService.info('Aktualizacje zostały dodane do wykonania.', 'Błąd', options);
              this.startUpdate.emit();
            },
            error => {
              const options = {closeButton: true, tapToDismiss: true, progressBar: true, positionClass: 'md-toast-bottom-right'};
              this.toastService.error('Wystąpił bład podczas zapisywnaia danych aktualizacji do wykonania.', 'Błąd', options);
            },
          );
        }
        this.modalRef.hide();
      }
    );

  }
}
