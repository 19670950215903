import {Route, RouterModule} from '@angular/router';
import {AuthGuard} from '../auth/guard/auth.guard';
import {NgModule} from '@angular/core';
import {ServiceViewComponent} from './components/service-view/service-view.component';
import {ServiceLimitsResolve, ServicesResolve} from './services-resolve.service';

const SERVICES: Route[] = [
  {
    path: 'uslugi',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: <any>ServiceViewComponent,
        resolve: {
          serviceLimits: ServiceLimitsResolve,
          services: ServicesResolve,
        }
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(SERVICES)
  ],
  exports: [
    RouterModule
  ]
})

export class ServicesRoutingModule {}
