<div class="modal-content">
  <div class="modal-header light-blue darken-3 white-text">
    <h4 class="modal-title w-100" id="myModalLabel">{{'Aktualizacja aplikacji'}}</h4>
  </div>
  <div *ngIf="isDataLoaded" class="modal-body">
    <h5>{{'Wybierz aplikacje do zaktualizowania. Aktualizacja może zająć kilka minut.'}}</h5>
    <form [formGroup]="definitionForm">
      <div class="row">
        <div class="col">
          <div class="md-form">
            <mdb-date-picker [locale]="'pl'" formControlName="date" required mdbValidate [validateSuccess]="false"
                             label="Data" buttonLabel="Ustaw" buttonClearLabel="Wyczyść"></mdb-date-picker>
          </div>
        </div>
        <div class="col">
          <div class="md-form">
            <mdb-time-picker formControlName="time" required mdbValidate [validateSuccess]="false"
                             label="Godzina" buttonLabel="Ustaw" buttonClearLabel="Wyczyść">
            </mdb-time-picker>
          </div>
        </div>
      </div>
    </form>
    <div *ngIf="softwareUpdateManualDef.updateDefs?.length>0" class="row mt-4">
      <div class="col-12 table-responsive" style="max-height: 400px; overflow-y: scroll;">
        <table mdbTable small="true" width="100%">
          <thead>
          <tr>
            <th><mdb-checkbox (change)="onChangeSelectAll($event)"></mdb-checkbox></th>
            <th><span class="mx-2 my-2">Nazwa</span></th>
            <th><span class="mx-2 my-2">Aplikacja</span></th>
            <th><span class="mx-2 my-2">Wersja</span></th>
            <th><span class="mx-2 my-2">Aktualna</span></th>
            <th><span class="mx-2 my-2">Status</span></th>
          </tr>
          </thead>
          <tbody style="max-height: 175px; overflow-y: scroll;">
          <tr *ngFor="let software of softwareUpdateManualDef.updateDefs" [ngClass]="{'text-danger' : !software.subscriptionActive}">
            <td>
              <mdb-checkbox [(ngModel)]="software.active" [disabled]="!software.subscriptionActive"></mdb-checkbox>
            </td>
            <td>{{software.software}}</td>
            <td>{{software.application}}</td>
            <td>{{software.version}}</td>
            <td>
              <mdb-checkbox [checked]="software.isCurrentVersion" [disabled]="true"></mdb-checkbox>
            </td>
            <td>{{software.subscriptionActive ? (!software.isCurrentVersion ? 'Aktualizacja' : software.active ? 'Odświeżenie' : '') : 'Subskrypcja wygasła'}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-default waves-light" (click)="onYesClick()" mdbRippleRadius>
      Aktualizuj
    </button>
    <button class="btn btn-outline-default ml-auto" data-dismiss="modal" (click)="onNoClick()"
            mdbRippleRadius>Anuluj
    </button>
  </div>
</div>




