<!--<p>-->
  <!--file-uploader works!-->
<!--</p>-->
<!--<div class="container">-->
  <!--<div class="columns is-multiline">-->
<!--<div class="row">-->
    <!--<div class="col-12">-->
      <!--<div class="drop-container" ngFileDrop [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">-->
        <!--<p>-->
          <!--Przeciągnij pliki tutaj lub-->
          <!--<label class="upload-button">-->
            <!--<input type="file" ngFileSelect [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" multiple>-->
            <!--wybierz-->
          <!--</label>-->
          <!--aby wysłać.-->
        <!--</p>-->
      <!--</div>-->
      <div class="drop-container"  mdbFileDrop (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
        <p>
          Przeciągnij pliki tutaj lub
          <label class="btn btn-primary btn-sm waves-light upload-button">
          <!--<label class="upload-button">-->
            <input type="file" mdbFileSelect  (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" multiple>
            wybierz
          </label>
          aby wysłać.
        </p>
      </div>
    <!--</div>-->
<!--</div>-->
    <!--<div class="col-12">-->
      <div class="upload-items">
        <div class="upload-item" *ngFor="let f of files; let i = index;">
              <span class="filename">
                <span>{{ f.name }} - {{ f.progress?.data?.percentage }}%</span>
                <span> z prędkością {{ f.progress?.data?.speedHuman }}</span>
                <span> pozostały czas {{ f.progress?.data?.etaHuman }}</span>
              </span>
          <span  style="float: right; cursor: pointer;"  (click)="cancelUpload(f.id)"><i class="fa fa-times pink-text"></i></span>
          <mdb-progressbar value="{{f?.progress?.data?.percentage}}" min="0" max="100" type="success" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></mdb-progressbar>
        </div>
      </div>
    <!--</div>-->
  <!--</div>-->
<!--</div>-->

