import {Component, Input, OnInit} from '@angular/core';
import {DynamicTable} from '../../../shared/models/table';
import {ActivatedRoute, Router} from '@angular/router';
import {LicencesService} from '../../licences.service';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';
import {UpdateListDialogComponent} from '../modals/update-list-dialog/update-list-dialog.component';
import {FilesService} from '../../../files/files.service';
import {TableSettingsService} from '../../../shared/services/table-settings.service';

@Component({
  selector: 'app-licence-list',
  templateUrl: './licence-list.component.html',
})
export class LicenceListComponent implements OnInit {
  licencesTable: DynamicTable;
  licenceId = 0;
  licenceViewStatus = 0;
  @Input() refCustomerId = 0;
  modalRef: MDBModalRef;

  constructor(private licenceService: LicencesService,
              private tableSettingsService: TableSettingsService,
              private route: ActivatedRoute,
              private router: Router,
              private modalService: MDBModalService) {
  }

  ngOnInit() {
    if (this.refCustomerId === 0) {
      this.licencesTable = this.route.snapshot.data['licences'];
    } else {
      const pageParam = 'paging.page=1&paging.recordsPerPage=10';
      // const filterParam = '&filter.ConCusId=' + this.refCustomerId;
      this.licenceService.getAll(pageParam).subscribe((data) => {
        const result: any = data;
        this.licencesTable = result;
      });
    }
  }

  licencesReload() {
    const parameters = this.tableSettingsService.getRequestParams('LicencesList');
    this.licenceService.getAll(parameters).subscribe((data) => {
      this.licencesTable = data;
    });
  }

  licenceReturn(reload) {
    if (reload){
      this.licencesReload();
    }
    this.licenceViewStatus = 0;

  }

  clickDetails(id: number){
    const modalOptions = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog modal-full-height modal-top w-100 modal-dialog-scrollable',
      containerClass: '',
      animated: true,
      data: {
        licenceId: id,
      }
    };

    this.modalRef = this.modalService.show(UpdateListDialogComponent, modalOptions);
    this.modalRef.content.action.subscribe((result: any) => {
      this.modalRef.hide();
    });
  }


}
