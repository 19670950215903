<mdb-tabset #staticTabs [buttonClass]="'md-tabs tabs-2 pills-primary white mt-3'" [contentClass]="'card'">
  <mdb-tab heading="Dane podstawowe">
    <app-user-data></app-user-data>
  </mdb-tab>
  <mdb-tab heading="Powiadomienia">
    <app-notifications></app-notifications>
  </mdb-tab>
  <mdb-tab heading="Schemat kolorystyczny">
    <app-skin></app-skin>
  </mdb-tab>
</mdb-tabset>
