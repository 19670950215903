<div *ngIf="isDataLoaded" class="modal-content">
  <div class="modal-header darken-3 white-text" [ngClass]="{'light-blue': !isNotAvailable, 'warning-color': isNotAvailable}">
    <h4 *ngIf="!isNotAvailable" class="modal-title w-100" style="text-align: center">Dostępne aktualizacje</h4>
    <h4 *ngIf="isNotAvailable" class="modal-title w-100" style="text-align: center" >Aktualizacja niedostępna</h4>
  </div>
  <div class="modal-body">
    <div class="panel-cascade" style="background: white">
      <app-table-main *ngIf="!isNotAvailable && isDataLoaded"
                      (selectedIdChange)="clickChooseCustomer($event)"
                      tableCaption="Dostępne aktualizacje"
                      [dynamicTable]="updatesTable"
                      [showFilter]="false"
                      [showHeader]="false"
                      [showFooter]="false"
                      [clientSide]="true"

      ></app-table-main>
      <div *ngIf="isNotAvailable" class="row">
        <div class="col">
          <h3 class="text-center"><i class="fa fa-exclamation-triangle text-warning"></i> Brak dostępnych plików aktualizacji</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-center">
    <!--    <button class="btn btn-default waves-light" (click)="clickYes()" mdbRippleRadius>-->
    <!--      Zatwierdź-->
    <!--    </button>-->
    <button class="btn btn-outline-default" data-dismiss="modal" (click)="clickNo()"
            mdbRippleRadius>Zamknij
    </button>
  </div>
</div>
