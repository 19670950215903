<th *ngIf="multiSelect"></th>
<th *ngFor="let cusHead of tableHeaders | showColumn" [ngClass]="{'filter-active' : cusHead.filterValue }"
    class="pr-1 mt-0 mb-0">
  <div *ngIf="cusHead.type==7 || cusHead.type==8" class="mt-0 mb-2">
    <mdb-select-2 [(ngModel)]="cusHead.filterValue" [multiple]="cusHead.type==8"  (ngModelChange)="getSelectedValue($event, cusHead)">
      <mdb-select-option *ngFor="let option of cusHead.listItems" [value]="option.value"  >{{ option.label }}</mdb-select-option>
    </mdb-select-2>
  </div>
  <div *ngIf="cusHead.type==9" class="md-form form-sm mt-0 mb-2">
    <mdb-select-2 [(ngModel)]="cusHead.filterValue" [multiple]="false"  (ngModelChange)="getSelectedValue($event, cusHead)">
      <mdb-select-option *ngFor="let option of checkboxOptions" [value]="option.value"  >{{ option.label }}</mdb-select-option>
    </mdb-select-2>
  </div>
  <div *ngIf="cusHead.type!=7 && cusHead.type!=8 && cusHead.type!=9" class="md-form form-sm mt-0 mb-0">
    <input type="text" class="form-control" [(ngModel)]="cusHead.filterValue" (change)="filterChange()">
  </div>
</th>

