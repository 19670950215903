import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LicenceListComponent } from './components/licence-list/licence-list.component';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {MDBBootstrapModulesPro} from 'ng-uikit-pro-standard';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {LicencesRoutingModule} from './licences-routing.module';
import {LicencesResolve} from './licences-resolve.service';
import { UpdateListDialogComponent } from './components/modals/update-list-dialog/update-list-dialog.component';

@NgModule({
  declarations: [LicenceListComponent, UpdateListDialogComponent],
  imports: [
    BrowserModule,
    CommonModule,
    LicencesRoutingModule,
    FormsModule,
    HttpClientModule,
    MDBBootstrapModulesPro.forRoot(),
    ReactiveFormsModule,
    RouterModule,
    SharedModule
  ],
  providers: [LicencesResolve]
})
export class LicencesModule { }
