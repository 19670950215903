import { Component, OnInit } from '@angular/core';
import {DynamicTable} from '../../../shared/models/table';
import {ActivatedRoute, Router} from '@angular/router';
import {TrainingsService} from '../../trainings.service';

@Component({
  selector: 'app-training-list',
  templateUrl: './training-list.component.html',
  styleUrls: ['./training-list.component.scss']
})
export class TrainingListComponent implements OnInit {
  trainingsTable: DynamicTable;
  trainingId = 0;
  trainingViewStatus = 0;
  constructor(private trainingService: TrainingsService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    // debugger
    this.trainingsTable = this.route.snapshot.data['trainings'];
  }

  trainingsReload() {
    const pageParam = 'code=' + this.trainingsTable.code + '&view=' + this.trainingsTable.view
      + '&paging.page=' + this.trainingsTable.paging.page + '&paging.recordsPerPage='
      + this.trainingsTable.paging.recordsPerPage
    let filterParam = '';
    this.trainingsTable.heads.forEach(item => {
      if (item.filterValue) {
        filterParam += '&filter.' + item.filterField + '=' + item.filterValue;
      }
    });
    let sortParam = '';
    const sortField = this.trainingsTable.heads.find(item => item.sortingDirection > 0);
    if (sortField) {
      sortParam = '&sort=' + sortField.name + '&sortDirection=' + sortField.sortingDirection;
    }
    this.trainingService.getAvailableTrainings(pageParam + filterParam + sortParam).subscribe((data) => {
      const result: any = data;
      this.trainingsTable.paging = result.paging;
      this.trainingsTable.rows = result.rows;
      result.heads.forEach(item => {
        const headItem = this.trainingsTable.heads.find(h => h.name === item.name);
        if (headItem) {
          item.sortingDirection = headItem.sortingDirection;
        }
      });
      this.trainingsTable.heads = result.heads;
    });
  }

  trainingAdd() {
    this.trainingId = -1;
    this.trainingViewStatus = 1;
  }

  trainingEdit(id) {

    this.trainingId = id;
    this.trainingViewStatus = 2;
  }

  trainingReturn(reload) {
    if (reload){
      this.trainingsReload();
    }
    this.trainingViewStatus = 0;

  }
}
