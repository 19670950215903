import {Route, RouterModule} from '@angular/router';
import {AuthGuard} from '../auth/guard/auth.guard';
import {NgModule} from '@angular/core';
import {LicenceListComponent} from './components/licence-list/licence-list.component';
import {LicencesResolve} from './licences-resolve.service';

const LICENCES: Route[] = [
  {
    path: 'licencje',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: <any>LicenceListComponent,
        resolve: {
          licences: LicencesResolve
        }
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(LICENCES)
  ],
  exports: [
    RouterModule
  ]
})

export class LicencesRoutingModule {}
