import { Component, OnInit } from '@angular/core';
import {MDBModalRef} from 'ng-uikit-pro-standard';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent implements OnInit {
  confirmForm: UntypedFormGroup;
  heading: string;
  content: any;
  btnColor = 'default';
  action: Subject<any> = new Subject();
  comment: string;
  showYesBtn = true;
  showMiddleBtn = false;
  yesBtnCaption = 'Zatwierdź';
  middleBtnCaption = '';

  constructor(public modalRef: MDBModalRef,
              private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.confirmForm =  this.buildForm();
  }

  buildForm() {
    return this.formBuilder.group({
      name: [this.content.name],
    });
  }

  clickYes(type: number) {
    this.action.next({ status: true, type: type, name: this.confirmForm.controls['name'].value});
  }

  clickNo() {
    this.action.next({ status: false });
  }

}
