<div class="row">
  <div class="col-12">
    <app-table-main *ngIf="updatesTable"
                    (reload)="reload()"
                    tableCaption="Historia aktualizacji"
                    [dynamicTable]="updatesTable">
    </app-table-main>
  </div>
</div>

