import {ListItem} from "./list-item";

export interface DynamicTable {
  heads: TableHead[];
  rows: TableRow[];
  paging: Paging;
  code: string;
  view: string;
}

export interface TableRow {
  attributes: string[];
}

export interface TableHead {
  id: number;
  type: number;
  width: string;
  name: string;
  list: ListItem[];
  sortingDirection: number;
  title: string;
  filterValue: string;
  filterField: string;
  show: boolean;
}

export interface TableHeadSetting {
  name: string;
  order: number;
  show: boolean;
  title: string;
}

export interface TableFilterSetting {
  name: string;
  value: string;
}

export class ViewSetting {
  columns: TableHeadSetting[];
  filters: TableFilterSetting[];
  name: string;
}

export interface SortingCache {
  sort: string;
  sortDirection: number;
}

export interface FilterCache {
  filterValue: string;
  filterField: string;
}


export interface Paging {
  recordsCount: number;
  from: number;
  to: number;
  recordsPerPage: number;
  pagesCount: number;
  page: number;
}
