<table *ngIf="documents.length>0 else noData " mdbTable small="true" width="100%">
  <thead>
  <tr>
    <th>Nazwa</th>
    <th *ngIf="detailsView">Rozmiar</th>
<!--    <th *ngIf="detailsView">Stworzony przez</th>-->
    <th></th>
  </tr>
  </thead>
  <tbody style="max-height: 400px; overflow-y: scroll;">
  <tr *ngFor="let document of documents">
<!--    <td [ngClass]="{'link-to-open': downloadFile.observers.length}" (click)="onDownloadFile(document.id)">-->
<!--      <i *ngIf="document.kind == 1" class="far fa-file-powerpoint fa-1-5x mr-2"></i>-->
<!--      <i *ngIf="document.kind == 0" class="far fa-file fa-1-5x mr-2"></i>-->
<!--      {{document.fileName}}</td>-->
    <td class="link-to-open" (click)="onDownloadFile(document.fileId)" style="color: royalblue; cursor: pointer;">
      <i *ngIf="document.kind == 1" class="far fa-file-powerpoint fa-1-5x mr-2"></i>
      <i *ngIf="document.kind == 0" class="far fa-file fa-1-5x mr-2"></i>
      {{document.fileName}}</td>
    <td *ngIf="detailsView">{{document.fileSize}}</td>
<!--    <td *ngIf="detailsView">{{document.createdBy}}</td>-->
    <td style="width:80px;">
      <div class="btn-group btn-group-sm float-right">
        <button *ngIf="replaceFile.observers.length" class="my-1 mx-0 px-3 btn btn-default btn-sm btn-outline-default"
                (click)="onReplaceFile(document)" title="Podmień plik"><i class="fa fa-upload"></i>
        </button>
        <button *ngIf="deleteFile.observers.length && showDelete" class="my-1 mx-0 px-3 btn btn-outline-danger btn-sm"
                (click)="onDeleteFile(document.id)" title="Usuń"><i class="fa fa-trash"></i>
        </button>
      </div>
    </td>
  </tr>
  </tbody>
</table>
<ng-template #noData><span>Brak dokumentów</span></ng-template>
