import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {PasswordChangeItem} from '../../models/password';
import {AuthService} from '../../auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastService} from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-reset-password-attempt',
  templateUrl: './reset-password-attempt.component.html',
  styleUrls: ['./reset-password-attempt.component.scss']
})
export class ResetPasswordAttemptComponent implements OnInit {
  passwordForm: UntypedFormGroup;
  guid: string;
  isError = false;
  resultStatus: string;

  constructor(private formBuilder: UntypedFormBuilder,
              private authService: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private toastService: ToastService) {
  }

  ngOnInit() {
    this.passwordForm = this.buildPasswordForm();
    this.guid = this.route.snapshot.queryParams['guid'];
  }

  buildPasswordForm() {
    return this.formBuilder.group({
      loginEmail: ['', [Validators.required]],
    });
  }

  submit() {
    this.authService.resetPasswordAttempt(this.passwordForm.value['loginEmail'])
      .subscribe(
        data => {
          this.resultStatus = data as string;
          this.isError = false;
          const options = {closeButton: true, tapToDismiss: false, positionClass: 'md-toast-bottom-full-width'};
          this.toastService.info('Na adres email wysłano link do zmiany hasła.', 'Potwierdzenie', options);
        },
        error => {
          this.resultStatus = 'Konto nie istnieje lub jest nieaktywne.'
          this.isError = true;
          const options = { closeButton: true, tapToDismiss: false, positionClass: 'md-toast-bottom-full-width'};
          this.toastService.error('Konto nie istnieje lub jest nieaktywne.', 'Błąd', options);
        });
  }
}
