<div *ngIf="isDataLoaded">
  <!--<div>-->
  <!--<div *ngIf="refCriticalityId===0; else caption"-->
  <!--class="view form-header p-2 mt-0 align-items-center">-->
  <!--<h4 class="h4-responsive">{{isAdd ? 'Dodawanie kontaktu' : 'Edycja kontaktu'}}</h4>-->
  <!--</div>-->

  <!--<ng-template #caption>-->
  <div class="view form-header p-2 mt-0 mb-0 d-flex justify-content-between align-items-center">
    <button (click)="return()" class="btn btn-default waves-light btn-sm px-3" mdbTooltip="Powrót" placement="right">
      <i
        class="fa fa-arrow-left"></i></button>
    <h5 class="font-bold">{{trainingItem.title}}</h5>
    <div></div>
  </div>
    <div class="row ">
      <div class="col-lg-6">
        <div class="card-body">
          <h5 class="mt-4 pb-2">Opis</h5>
          <div class="row">
            <div class="col-12">
              <div class="md-form mb-2">
                <input class="form-control form-control-sm" value="{{trainingItem.title}}" id="fTitle" mdbInput mdbValidate
                       type="text"  disabled>
                <label class for="fTitle">Tytuł</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <div class="md-form mb-2">
                <input class="form-control form-control-sm" value="{{trainingItem.from}}" id="fFrom"
                       mdbInput mdbValidate type="text" disabled>
                <label class for="fFrom">Rozpoczęcie</label>
              </div>
            </div>
            <div class="col-4">
              <div class="md-form mb-2">
                <input class="form-control form-control-sm" value="{{trainingItem.to}}" id="fTo"
                       mdbInput mdbValidate type="text" disabled>
                <label class for="fTo">Zakończenie</label>
              </div>
            </div>
            <div class="col-4">
              <div class="md-form mb-2">
                <input class="form-control form-control-sm" value="{{trainingItem.totalDays}}" id="fTotalDays"
                       mdbInput mdbValidate type="text" disabled>
                <label class for="fTotalDays">Ilość dni</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="md-form">
                <input class="form-control form-control-sm" value="{{trainingItem.description}}" id="fDescription"
                       mdbInput mdbValidate type="text" disabled>
                <label class for="fDescription">Opis</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="md-form">
                <input class="form-control form-control-sm" value="{{trainingItem.totalSeats}}" id="fTotalSeats"
                       mdbInput mdbValidate type="text" disabled>
                <label class for="fTotalSeats">Ilość miejsc</label>
              </div>
            </div>
            <div class="col-6">
              <div class="md-form ">
                <input class="form-control form-control-sm" value="{{trainingItem.availableSeats}}" id="fAvailableSeats"
                       mdbInput mdbValidate type="text" disabled>
                <label class for="fAvailableSeats">Wolne miejsca</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card-body h-100">
          <h5 class="my-4 pb-2">Rejestracja</h5>
          <div class="row mb-3">
            <div class="col-12">
              <app-application-add-edit *ngIf="addingApplication" [refTrainingId]="trainingItem?.id" class="mb-4" (save)="saveApplication()"
                                (cancel)="cancelApplication()"
                                ></app-application-add-edit>
              <button *ngIf="!addingApplication && trainingItem.availableSeats>0" class="btn btn-default waves-light btn-sm ml-0"
                      (click)="addApplication()"
                      mdbRippleRadius>
                Dodaj zgłoszenie
              </button>
              <h5 *ngIf="trainingItem.availableSeats==0" class="text-danger"><strong>Brak wolnych miejsc</strong></h5>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
