<div *ngIf="isDataLoaded">
  <div class="view form-header p-2 mt-0 mb-0 d-flex justify-content-between align-items-center">
    <div>
      <button (click)="return()" class="btn btn-default waves-light btn-sm px-3" title="Powrót">
        <i
          class="fa fa-arrow-left"></i></button>
    </div>
    <h5
      class="font-bold">{{messageItem.id ? messageItem.subject : 'Nie znaleziono wiadomości lub nie masz uprawnień do jej przeglądania.' }}</h5>
    <div></div>
  </div>
  <div *ngIf="messageItem.id">
    <div class="row">
      <div class="col-md-8">
        <div class="card-body h-100">
          <h5 class="mt-4 pb-2">Wiadomość</h5>
          <div class="row">
            <div class="col">
              <div class="md-form mb-2">
                <label class="active text-black" for="fSubject">Dotyczy</label>
                <div id="fSubject" class="text-muted pt-3">
                  <span>{{messageItem.subject}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="md-form mb-2">
                <label class="active text-black">Treść wiadomości</label>
                  <div class="pt-3" [innerHtml]="htmlContent"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card-body h-100">
          <div class="row">
            <h5 class="mt-4 pb-2">Załączniki</h5>
          </div>
          <div class="row">
            <div class="col-12">
              <app-document-list [documents]="messageItem.documents"
                                 (downloadFile)="clickDownloadFile($event)"
                                 [detailsView]="false"
              ></app-document-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



