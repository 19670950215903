import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastService} from 'ng-uikit-pro-standard';
import {PasswordChangeItem} from '../../models/password';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  passwordForm: UntypedFormGroup;
  passwordItem: PasswordChangeItem;
  returnUrl: string;

  constructor(private formBuilder: UntypedFormBuilder,
              private authService: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private toastService: ToastService) {
  }

  ngOnInit() {
    this.passwordForm = this.buildPasswordForm();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    // this.passwordGroup = this.passwordForm .controls.matching_password;
  }

  buildPasswordForm() {
    return this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      newPassword2: ['', Validators.required]
    });
  }



  onSubmit() {
    // debugger;
    this.passwordItem = Object.assign({}, this.passwordForm.value);
    this.authService.changePassword(this.passwordItem.oldPassword, this.passwordItem.newPassword)
      .subscribe(
        data => {
          this.onSubmitSuccess();
        },
        error => {
          this.onSubmitFailure();
        });
  }

  private onSubmitSuccess() {
    const options = {closeButton: true, tapToDismiss: true, progressBar: true};
    this.toastService.info('Hasło zostało zmienione', 'Potwierdzenie', options);
    if (!this.returnUrl || this.returnUrl === '/') {
      this.returnUrl = '/panel';
    }
    this.router.navigate([this.returnUrl]).then(() => {
    });
  }

  private onSubmitFailure() {
    const options = {closeButton: true, tapToDismiss: true, progressBar: true, positionClass: 'md-toast-bottom-right'};
    this.toastService.error('Podczas póby zmiany hasła wystąpił błąd', 'Błąd', options);
  }

  cancel() {
    this.router.navigate([this.returnUrl]);
  }
}
