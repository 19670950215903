import {Route, RouterModule} from '@angular/router';
import {AuthGuard} from '../auth/guard/auth.guard';
import {NgModule} from '@angular/core';
import {MessageListComponent} from './components/message-list/message-list.component';
import {MessagesResolve} from './messages-resolve.service';
import {MessageShowComponent} from './components/message-show/message-show.component';

const MESSAGES: Route[] = [
  {
    path: 'wiadomosci',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: <any>MessageListComponent,
        resolve: {
          messages: MessagesResolve
        }
      },
      {
        path: ':id',
        component: <any>MessageShowComponent,
      },
      {
        path: 'mg/:id',
        component: <any>MessageShowComponent,
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(MESSAGES)
  ],
  exports: [
    RouterModule
  ]
})

export class MessagesRoutingModule {}
