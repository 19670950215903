import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {CaseDashboard, PersonFullData, TrainingDashboard} from './models/dashboard';
import {DashboardService} from './dashboard.service';

@Injectable()
export class ProfileResolve implements Resolve<PersonFullData> {
  constructor(private dashboardService: DashboardService) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.dashboardService.getProfile();
  }
}

@Injectable()
export class CaseResolve implements Resolve<CaseDashboard> {
  constructor(private dashboardService: DashboardService) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.dashboardService.getCases();
  }
}

@Injectable()
export class TrainingResolve implements Resolve<TrainingDashboard> {
  constructor(private dashboardService: DashboardService) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.dashboardService.getTrainings();
  }
}
