import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Paging} from '../../models/table';
import {TableSettingsService} from '../../services/table-settings.service';

@Component({
  selector: 'app-table-paging',
  templateUrl: './table-paging.component.html',
  styleUrls: ['./table-paging.component.scss']
})
export class TablePagingComponent implements OnInit {
  @Input() paging: Paging;
  @Input() tableCode: string;
  @Input() showPageChoose = true;
  @Output() goToPage = new EventEmitter<any>();
  pagesToShow: 5;
  currentSetPage: number;
  currentRecordsPerPage: number;
  pages: number[];

  constructor(private tableService: TableSettingsService) {
  }

  ngOnInit() {
    this.currentSetPage = this.paging.page;
    this.currentRecordsPerPage = this.paging.recordsPerPage;
    this.pages = this.getPages();
  }

  onPage(index: number) {
    this.tableService.setPage(this.tableCode, index);
    this.paging.page = index;
    this.currentSetPage = this.paging.page;
    this.goToPage.emit(this.paging);
  }

  onPrev(): void {
    const index = --this.paging.page;
    this.onPage(index);
  }

  onNext(): void {
    const index = ++this.paging.page;
    this.onPage(index);
  }

  changePageSize() {
    this.paging.recordsPerPage = this.currentRecordsPerPage;
    this.tableService.setRecordsPerPage(this.tableCode, this.currentRecordsPerPage);
    this.onPage(1);
  }

  setPage() {
    this.currentSetPage = this.currentSetPage <= this.paging.pagesCount ? this.currentSetPage : this.paging.pagesCount;
    this.onPage(this.currentSetPage);
  }

  getPages(): number[] {
    const c = this.paging.pagesCount;
    const p = this.paging.page || 1;
    const pagesToShow = this.pagesToShow || 5;
    const pages: number[] = [];
    pages.push(p);
    const times = pagesToShow - 1;
    for (let i = 0; i < times; i++) {
      if (pages.length < pagesToShow) {
        if (Math.min.apply(null, pages) > 1) {
          pages.push(Math.min.apply(null, pages) - 1);
        }
      }
      if (pages.length < pagesToShow) {
        if (Math.max.apply(null, pages) < c) {
          pages.push(Math.max.apply(null, pages) + 1);
        }
      }
    }
    pages.sort((a, b) => a - b);
    return pages;
  }
}
