<div class="row">
  <div class="col-12">
    <app-table-main *ngIf="updatesTable"
                    (reload)="reload()"
                    tableCaption="Dostępne wersje aplikacji"
                    [dynamicTable]="updatesTable">
      <div externalButtons class="d-inline">
        <button class="btn btn-default waves-light btn-sm px-3"
                title="Aktualizuj aplikacje" placement="right" (click)="clickUpdateManual()">
          <i class="fa-1-2x fas fa-cloud-upload-alt"></i>
        </button>
      </div>
    </app-table-main>
  </div>
</div>

