import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrainingListComponent } from './components/training-list/training-list.component';
import {MDBBootstrapModulesPro} from 'ng-uikit-pro-standard';
import {BrowserModule} from '@angular/platform-browser';
import {FilesModule} from '../files/files.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {TrainingsResolve} from './trainings-resolve.service';
import { TrainingViewComponent } from './components/training-view/training-view.component';
import { ApplicationAddEditComponent } from './components/application-add-edit/application-add-edit.component';

@NgModule({
  declarations: [TrainingListComponent, TrainingViewComponent, ApplicationAddEditComponent],
  imports: [
    BrowserModule,
    CommonModule,
    FilesModule,
    FormsModule,
    HttpClientModule,
    MDBBootstrapModulesPro.forRoot(),
    ReactiveFormsModule,
    RouterModule,
    SharedModule
  ],
  providers: [TrainingsResolve]
})
export class TrainingsModule { }
