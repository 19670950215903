import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {MainHeaderComponent} from './components/main-header/main-header.component';
import {TableMainComponent} from './components/table-main/table-main.component';
import {TablePagingComponent} from './components/table-paging/table-paging.component';
import {DateWithTimePipe} from './pipes/date-with-time.pipe';
import {ShowColumnPipe} from './pipes/show-column.pipe';
import {CacheService} from './services/cache.service';
import {ListService} from './services/list.service';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MDBBootstrapModulesPro} from 'ng-uikit-pro-standard';
import {ListTextPipe} from './pipes/list-text.pipe';
import {ListCodePipe} from './pipes/list-code.pipe';
import {FileSizePipe} from './pipes/file-size.pipe';
import { TableFilterRowComponent } from './components/table-filter-row/table-filter-row.component';
import { LoaderDialogComponent } from './modals/loader-dialog/loader-dialog.component';
import { DocumentListComponent } from './components/document-list/document-list.component';
import { ConfirmDialogComponent } from './modals/confirm-dialog/confirm-dialog.component';

@NgModule({
  declarations: [PageNotFoundComponent, MainHeaderComponent, TableMainComponent, TablePagingComponent, DateWithTimePipe, ShowColumnPipe
    , ListTextPipe, ListCodePipe, FileSizePipe, TableFilterRowComponent, LoaderDialogComponent, DocumentListComponent, ConfirmDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    MDBBootstrapModulesPro.forRoot(),
    ReactiveFormsModule,
    RouterModule
  ],
    exports: [MainHeaderComponent, TablePagingComponent, TableMainComponent, ShowColumnPipe, DateWithTimePipe, ListTextPipe, ListCodePipe
        , FileSizePipe, DocumentListComponent],
  providers: [CacheService, ListService]
})
export class SharedModule {
}
