import {Component, Input, OnInit} from '@angular/core';
import {DynamicTable} from '../../../shared/models/table';
import {ServicesService} from '../../services.service';
import {TableSettingsService} from '../../../shared/services/table-settings.service';

@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.scss']
})
export class ServiceListComponent implements OnInit {
  @Input() serviceTable: DynamicTable;
  constructor(private servicesService: ServicesService,
              private tableSettingsService: TableSettingsService) { }

  ngOnInit(): void {
  }

  servicesReload(resetPage = false) {
    const parameters = this.tableSettingsService.getRequestParams('ServicesCustomerList');
    this.servicesService.getServices(parameters).subscribe((data) => {
      this.serviceTable = data;
    });
  }
}
